import React from "react";
import DataTemplate from "./../../components/DataTemplate.jsx";
import styled from "styled-components";
import AdminTemplate from "./../admin/template";
import { protectedResources } from "./../../authConfig";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 56px;
`;

const Export = (props) => {
    const [data, setData] = React.useState();
    const url = protectedResources.apiCapex.endpoint + "/admin/export";

    return (
        <AdminTemplate>
            <DataTemplate {...props} url={url} data={data} setData={setData}>
                <Container>
                    <span className="headline-l">Report - Capex summary</span>
                </Container>
            </DataTemplate>
        </AdminTemplate>
    );
};

export default Export;
