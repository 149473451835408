import React, { useState } from "react";
import DataTemplate from "./../../components/DataTemplate.jsx";
import { Link, useLocation } from "react-router-dom";
import { Box } from "@material-ui/core";
import RequestTemplate from "./template";
import { protectedResources } from "./../../authConfig";
import CapexDetails from "./../../components/CapexDetails";
import { Container, ColumnLeft, Column } from "./../../components/Layout";
import Text from "../../components/Text.jsx";

const RequestConfirm = (props) => {
    const [data, setData] = useState();
    const location = useLocation();

    return (
        <RequestTemplate>
            <DataTemplate
                {...props}
                url={protectedResources.apiCapex.endpoint + location.pathname}
                data={data}
                setData={setData}
            >
                <Container>
                    <ColumnLeft>
                        <Box marginBottom={"20px"}>
                            <span className="headline-l">
                                <Text id="request_create_confirmed_titel" />
                            </span>
                        </Box>
                        <Box marginBottom={"31px"}>
                            <span className="body-copy">
                                <Text id="request_create_confirmed_description" />
                            </span>
                        </Box>
                        <Link className="text-link" to="/request">
                            See all your requests
                        </Link>
                    </ColumnLeft>
                    <Column>
                        <CapexDetails request={data} />
                    </Column>
                </Container>
            </DataTemplate>
        </RequestTemplate>
    );
};

export default RequestConfirm;
