import React from "react";
import styled from "styled-components";
import AppBar from "../../components/AppBar.jsx";
import { Link } from "react-router-dom";

const Container = styled.div`
    display: flex;
    flex: 1;
`;

const ApproverTemplate = (props) => {
    return (
        <Container>
            <AppBar {...props}>
                <React.Fragment>
                    <Link to="/approver/requests" className="nav-link">
                        My active requests
                    </Link>
                </React.Fragment>
            </AppBar>
            {props.children}
        </Container>
    );
};

export default ApproverTemplate;
