import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
    typography: {
        fontFamily: ["Calibri", "sans-serif"].join(","),
    },
    palette: {
        background: {
            default: "#F0F0F0",
        },
        primary: {
            main: "#5A9CD3",
        },
        secondary: {
            main: "#FFF",
        },
    },
});

export default theme;
