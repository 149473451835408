import React from "react";
import styled from "styled-components";
import ErrorTemplate from "./template";
import Template from "../../components/Template";
import DogImage from "./../../img/dog.png";

const Container = styled.div`
    display: flex;
    padding: 20px;
`;

const Content = styled.div`
    display: flex;
    flex: 1;
`;

const Component = ({ error }) => {
    return (
        <ErrorTemplate>
            <Template>
                <Container>
                    <Content>
                        <span className="headline-l">
                            500 - Internal Server Error
                        </span>
                        <span className="input">{error && error}</span>
                    </Content>
                    <Content>
                        <img src={DogImage} />
                    </Content>
                </Container>
            </Template>
        </ErrorTemplate>
    );
};

export default Component;
