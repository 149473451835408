import React from "react";
import { Box } from "@material-ui/core";
import DownloadLink from "./DownloadLink";
import formatDate from "./../utils/formatDate";

const CapexDetails = (props) => {
    const { request } = props;
    const {
        amount,
        budgeted,
        site,
        investmentType,
        division,
        financeType,
        fileName,
        fileDescription,
    } = request ? request : {};
    return (
        <React.Fragment>
            <Box marginBottom={"31px"}>
                <span className="section">CAPEX DETAILS</span>
            </Box>
            <Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridRowGap={"22px"}
                marginBottom={"50px"}
            >
                <Box
                    gridColumn="span 12"
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                >
                    <Box gridColumn="span 6">
                        <span className="label">Request ID</span>
                    </Box>
                    <Box gridColumn="span 6">
                        <span className="label">Date requested</span>
                    </Box>
                    <Box gridColumn="span 6">
                        <span className="input">{request && request.id}</span>
                    </Box>
                    <Box gridColumn="span 6">
                        <span className="input">
                            {request && formatDate(request.created)}
                        </span>
                    </Box>
                </Box>
                <Box
                    gridColumn="span 12"
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                >
                    <Box gridColumn="span 6">
                        <span className="label">Site</span>
                    </Box>
                    <Box gridColumn="span 6">
                        <span className="label">Division</span>
                    </Box>
                    <Box gridColumn="span 6">
                        <span className="input">{site && site.name}</span>
                    </Box>
                    <Box gridColumn="span 6">
                        <span className="input">
                            {division && division.name}
                        </span>
                    </Box>
                </Box>
                <Box
                    gridColumn="span 12"
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                >
                    <Box gridColumn="span 6">
                        <span className="label">Type of investment</span>
                    </Box>
                    <Box gridColumn="span 6">
                        <span className="label">Fiscal year</span>
                    </Box>
                    <Box gridColumn="span 6">
                        <span className="input">
                            {investmentType && investmentType.name}
                        </span>
                    </Box>
                    <Box gridColumn="span 6">
                        <span className="input">
                            {request && request.fiscalYear}
                        </span>
                    </Box>
                </Box>
                <Box
                    gridColumn="span 12"
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                >
                    <Box gridColumn="span 6">
                        <span className="label">Start date</span>
                    </Box>
                    <Box gridColumn="span 6">
                        <span className="label">End date</span>
                    </Box>
                    <Box gridColumn="span 6">
                        <span className="input">
                            {request && formatDate(request.startDate)}
                        </span>
                    </Box>
                    <Box gridColumn="span 6">
                        <span className="input">
                            {request && formatDate(request.endDate)}
                        </span>
                    </Box>
                </Box>
                <Box
                    gridColumn="span 12"
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                >
                    <Box gridColumn="span 3">
                        <span className="label">Amount</span>
                    </Box>
                    <Box gridColumn="span 3">
                        <span className="label">Budgeted request</span>
                    </Box>
                    <Box gridColumn="span 3">
                        <span className="label">Finance options</span>
                    </Box>
                    <Box gridColumn="span 3"></Box>
                    <Box gridColumn="span 3">
                        <span className="input">{amount} kEUR</span>
                    </Box>
                    <Box gridColumn="span 3">
                        <span className="input">
                            {budgeted ? "Budget" : "Non-budget"}
                        </span>
                    </Box>
                    <Box gridColumn="span 3">
                        <span className="input">
                            {financeType && financeType.name}
                        </span>
                    </Box>
                </Box>
                {fileName && (
                    <Box
                        gridColumn="span 12"
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                    >
                        <Box gridColumn="span 12">
                            <span className="label">File attachment</span>
                        </Box>
                        <Box gridColumn="span 7">
                            <span className="input">{fileDescription}</span>
                        </Box>
                        <Box gridColumn="span 5">
                            <DownloadLink fileName={fileName} />
                        </Box>
                    </Box>
                )}
            </Box>
        </React.Fragment>
    );
};

export default CapexDetails;
