import * as yup from "yup";
import React from "react";
import { Formik, Form, FieldArray } from "formik";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { ButtonGroup, Button, FormControl, Box } from "@material-ui/core";
import { capexApiPost, capexApiDelete, capexApiPut } from "../../fetch";

const validationSchema = yup.object({});

const useStyles = makeStyles((theme) => ({
    fieldArray: {
        paddingTop: "10px",
        display: "flex",
        flexDirection: "column",
    },
    field: {
        display: "flex",
        flexDirection: "row",
    },
}));

const StyledButton = withStyles((theme) => ({
    root: {
        textTransform: "unset !important",
        height: "60px",
        backgroundColor: "#191919",
        color: "#FFF",
    },
}))(Button);

const AddButton = withStyles((theme) => ({
    root: {
        textTransform: "unset !important",
        height: "60px",
    },
}))(Button);

const FormComponent = (props) => {
    const { data, initialValues, url, onHandleSubmit } = props;
    const classes = useStyles();

    const handleSubmit = async (values) => {
        const { items } = values;
        const removed = data.filter(
            (aData) => !items.some((aItem) => aItem.id == aData.id)
        );

        const added = items.filter(
            (aItem) => !data.some((aData) => aData.id == aItem.id)
        );

        const updated = items.filter(
            (aItem) =>
                !data.some(
                    (aData) => JSON.stringify(aItem) === JSON.stringify(aData)
                ) && !added.some((aData) => aData.id == aItem.id)
        );

        await Promise.all(
            added.map((item) => {
                const json = JSON.stringify(item);
                return new Promise(async (resolve) => {
                    const result = await capexApiPost(url, json);
                    resolve(result);
                });
            })
        );

        await Promise.all(
            removed.map((item) => {
                const deletedItem = { ...item, deleted: true };
                const json = JSON.stringify(deletedItem);
                return new Promise(async (resolve) => {
                    const result = await capexApiPost(url + "/edit", json);
                    resolve(result);
                });
            })
        );

        await Promise.all(
            updated.map((item) => {
                return new Promise(async (resolve) => {
                    const json = JSON.stringify(item);
                    const result = await capexApiPost(url + "/edit", json);
                    resolve(result);
                });
            })
        );

        onHandleSubmit();
    };

    return (
        <Formik
            cx={{ width: "100%" }}
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={(values) => {
                handleSubmit(values);
            }}
        >
            {({ values }) => {
                return (
                    <Form style={{ width: "100%" }}>
                        <FormControl fullWidth component="fieldset">
                            <Box
                                display="grid"
                                gridTemplateColumns="repeat(12, 1fr)"
                                gridColumnGap={10}
                            ></Box>
                            <FieldArray
                                name="items"
                                render={(fieldHelpers) => {
                                    return (
                                        <div className={classes.fieldArray}>
                                            {values.items.length > 0 &&
                                                values.items.map(
                                                    (item, index) =>
                                                        props.makeField(
                                                            item,
                                                            index,
                                                            fieldHelpers
                                                        )
                                                )}
                                            <br />
                                            <AddButton
                                                type="button"
                                                color="primary"
                                                variant="contained"
                                                fullWidth
                                                disableElevation
                                                disableFocusRipple
                                                disableRipple
                                                onClick={() => {
                                                    fieldHelpers.push(
                                                        props.makeNewItem()
                                                    );
                                                }}
                                            >
                                                <span className="cta">
                                                    Add item
                                                </span>
                                            </AddButton>
                                        </div>
                                    );
                                }}
                            />
                            <br />
                            <ButtonGroup
                                color="primary"
                                fullWidth
                                disableElevation
                                disableFocusRipple
                                disableRipple
                            >
                                <StyledButton
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                >
                                    <span className="cta">Submit</span>
                                </StyledButton>
                            </ButtonGroup>
                        </FormControl>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default FormComponent;
