import React from "react";
import Template from "./../../components/Template.jsx";
import styled from "styled-components";
import ApproverTemplate from "./template";

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
`;

const Content = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

const ApproverRequestError = (props) => {
    return (
        <ApproverTemplate {...props}>
            <Template {...props}>
                <Container>
                    <Content>
                        <span className="headline-l">Request error</span>
                    </Content>
                    <Content>Request already handled.</Content>
                </Container>
            </Template>
        </ApproverTemplate>
    );
};

export default ApproverRequestError;
