import React from "react";
import { Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ApproverForm from "./form";
import DataTemplate from "./../../components/DataTemplate.jsx";
import AdminTemplate from "./../admin/template";
import { protectedResources } from "./../../authConfig";
import { capexApiPost } from "../../fetch";
import { Container, ColumnLeft, Column } from "./../../components/Layout";
import Text from "../../components/Text";

const ApproverCreate = (props) => {
    const [data, setData] = React.useState();
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const history = useHistory();

    const handleSubmit = async (values) => {
        const form = { ...values };
        setIsSubmitting(true);

        form.siteIDs = values.sites.map((aSite) => aSite.id);
        form.investmentTypeIDs = values.investmentTypes.map(
            (aType) => aType.id
        );

        const json = JSON.stringify(form);

        await capexApiPost(
            protectedResources.apiCapex.endpoint + "/approver/create/",
            json
        );

        history.push("/approver");
    };

    const initialValues = {
        graphUserId: "",
        budgeted: 0,
        investmentTypes: [],
        level: 1,
        maxAnually: 0,
        name: "",
        nonBudgeted: 0,
        sites: [],
    };

    return (
        <AdminTemplate {...props}>
            <DataTemplate
                {...props}
                url={protectedResources.apiCapex.endpoint + "/approver/create"}
                data={data}
                setData={setData}
            >
                <Container>
                    <ColumnLeft>
                        <Box gridColumn="span 12" marginBottom={"5px"}>
                            <span className="headline-15-caps">Admin</span>
                        </Box>
                        <Box marginBottom={"10px"}>
                            <span className="headline-l">Create Approver</span>
                        </Box>
                        <Box>
                            <span className="body-copy">
                                <Text id="approver_create_description" />
                            </span>
                        </Box>
                    </ColumnLeft>
                    <Column>
                        <ApproverForm
                            data={data}
                            initialValues={initialValues}
                            handleSubmit={handleSubmit}
                            isSubmitting={isSubmitting}
                        ></ApproverForm>
                    </Column>
                </Container>
            </DataTemplate>
        </AdminTemplate>
    );
};

export default ApproverCreate;
