import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { MenuItem, Menu, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import AppBar from "../../components/AppBar.jsx";
import { downloadDataDump } from "../../fetch.js";

const Container = styled.div`
    display: flex;
    flex: 1;
`;

const AdminTemplate = (props) => {
    const history = useHistory();
    const [reportsMenuAnchor, setReportsMenuAnchor] = React.useState(null);
    const [adminMenuAnchor, setAdminMenuAnchor] = React.useState(null);
    const reportsMenuOpen = Boolean(reportsMenuAnchor);
    const adminMenuOpen = Boolean(adminMenuAnchor);

    const showReportsSection = (section) => {
        setReportsMenuAnchor(null);

        if (!section) return;

        history.push("/report/" + section);
    };
    const showAdminSection = (section) => {
        setAdminMenuAnchor(null);

        if (!section) return;

        history.push("/admin/" + section);
    };

    return (
        <Container>
            <AppBar {...props}>
                <React.Fragment>
                    <Link to="/approver/create" className="nav-link">
                        Create approver
                    </Link>
                    <Link to="/approver" className="nav-link">
                        View/edit approvers
                    </Link>
                    <Button
                        aria-controls="reports-menu-button"
                        aria-haspopup="true"
                        aria-expanded={reportsMenuOpen ? "true" : undefined}
                        onClick={(event) => {
                            setReportsMenuAnchor(event.currentTarget);
                        }}
                    >
                        <span className="nav-menu">Reports</span>
                    </Button>
                    <Menu
                        anchorEl={reportsMenuAnchor}
                        open={reportsMenuOpen}
                        onClose={() => {
                            showReportsSection();
                        }}
                    >
                        <MenuItem
                            onClick={() => {
                                showReportsSection("capex");
                            }}
                        >
                            Report - Capex summary
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                showReportsSection("site");
                            }}
                        >
                            Report - Site summary
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                showReportsSection("requests");
                            }}
                        >
                            Capex requests
                        </MenuItem>
                    </Menu>
                    <Button
                        aria-controls="admin-menu-button"
                        aria-haspopup="true"
                        aria-expanded={adminMenuOpen ? "true" : undefined}
                        onClick={(event) => {
                            setAdminMenuAnchor(event.currentTarget);
                        }}
                    >
                        <span className="nav-menu">Admin</span>
                    </Button>
                    <Menu
                        anchorEl={adminMenuAnchor}
                        open={adminMenuOpen}
                        onClose={() => {
                            showAdminSection();
                        }}
                    >
                        <MenuItem
                            onClick={() => {
                                showAdminSection("sites");
                            }}
                        >
                            Manage sites
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                showAdminSection("investmentTypes");
                            }}
                        >
                            Manage investment types
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                showAdminSection("divisions");
                            }}
                        >
                            Manage divisions
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                showAdminSection("financeTypes");
                            }}
                        >
                            Manage finance types
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                setAdminMenuAnchor(null);

                                history.push("/texts/");
                            }}
                        >
                            Manage texts
                        </MenuItem>
                        <MenuItem
                            onClick={async () => {
                                await downloadDataDump();
                            }}
                        >
                            Download data dump (Excel)
                        </MenuItem>
                    </Menu>
                </React.Fragment>
            </AppBar>
            {props.children}
        </Container>
    );
};

export default AdminTemplate;
