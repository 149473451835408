import React from "react";
import styled from "styled-components";
import StatusPendingImage from "./../img/status-pending.png";
import StatusApprovedImage from "./../img/status-approved.png";
import StatusDeclinedImage from "./../img/status-declined.png";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const StatusText = styled.span`
    margin-left: 8px;
`;

const Status = (props) => {
    const getStatusElement = (status) => {
        switch (status) {
            case 0:
                return (
                    <Container>
                        <img src={StatusPendingImage} />
                        <StatusText className="body-copy">Pending</StatusText>
                    </Container>
                );

            case 1:
                return (
                    <Container>
                        <img src={StatusApprovedImage} />
                        <StatusText className="body-copy">Approved</StatusText>
                    </Container>
                );

            case 2:
                return (
                    <Container>
                        <img src={StatusPendingImage} />
                        <StatusText className="body-copy">
                            Approved but out-of-budget
                        </StatusText>
                    </Container>
                );

            case 3:
                return (
                    <Container>
                        <img src={StatusDeclinedImage} />
                        <StatusText className="body-copy">Declined</StatusText>
                    </Container>
                );

            case 4:
                return (
                    <Container>
                        <img src={StatusPendingImage} />
                        <StatusText className="body-copy">Revision</StatusText>
                    </Container>
                );

            default:
                return <Container></Container>;
        }
    };

    const element = getStatusElement(props.status);

    return element;
};

export default Status;
