export const formatDate = (dateString) => {
    const date = new Date(dateString);
    let a = [{ year: "numeric" }, { month: "numeric" }, { day: "numeric" }];
    const format = (m) => {
        let f0 = new Intl.DateTimeFormat("en", m);
        let f1 = f0.format(date);

        if (f1 < 10) {
            f1 = "0" + f1.toString();
        }

        return f1;
    };
    return a.map(format).join("-");
};

export const formatDateWithTime = (dateString) => {
    const date = new Date(dateString);
    let a = [{ year: "numeric" }, { month: "numeric" }, { day: "numeric" }];
    const format = (m) => {
        let f = new Intl.DateTimeFormat("en", m);
        return f.format(date);
    };

    const minutes = date.getMinutes();
    const minutesText = minutes < 10 ? "0" + minutes.toString() : minutes;

    return a.map(format).join("-") + " " + date.getHours() + ":" + minutesText;
};

export default formatDate;
