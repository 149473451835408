import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";

export const ContentGuard = ({ ...props }) => {
    const { instance } = useMsal();
    const [isAuthorized, setIsAuthorized] = useState(false);

    const onLoad = async () => {
        const currentAccount = instance.getActiveAccount();

        if (currentAccount && currentAccount.idTokenClaims["groups"]) {
            console.log("currentAccount.idTokenClaims[groups]", currentAccount.idTokenClaims["groups"]);
            let intersection = props.groups.filter((group) =>
                currentAccount.idTokenClaims["groups"].includes(group)
            );

            if (intersection.length > 0) {
                setIsAuthorized(true);
            }
        }
    };

    useEffect(() => {
        onLoad();
    }, [instance]);

    return <>{isAuthorized ? props.children : <></>}</>;
};
