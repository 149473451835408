import * as yup from "yup";
import React from "react";
import { Formik, Form, Field } from "formik";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DeleteIcon from "@material-ui/icons/Delete";
import DateFnsUtils from "@date-io/date-fns";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
    Box,
    Button,
    RadioGroup,
    ButtonGroup,
    FormControlLabel,
    IconButton,
    FormControl,
} from "@material-ui/core";
import FileUploadImage from "./../../img/file-upload.png";
import DeleteImage from "./../../img/delete.png";
import {
    StyledTextField,
    StyledDatePicker,
    CurrencyField,
    StyledRadio,
    StyledMUITextField,
    FileUploadButton,
    SubmitButton,
    CustomFastField,
} from "./../../components/Form";

const validationSchema = yup.object({}).shape({
    name: yup.string().required("Required"),
    amount: yup.number().moreThan(0).required("Required"),
    siteId: yup.number().required("Required"),
    investmentTypeId: yup.number().required("Required"),
    financeTypeId: yup.number().required("Required"),
    divisionId: yup.number().required("Required"),
    file: yup.string().required("Required"),
});

const RequestForm = (props) => {
    const {
        initialValues,
        handleSubmit,
        handleDelete,
        enableDelete,
        handleDeleteConfirm,
        data,
        isEditing,
        disabled,
    } = props;

    const {
        availableSites,
        availableInvestmentTypes,
        availableFinanceTypes,
        availableDivisions,
    } = data;

    const availableYears = [];
    const currentYear = new Date().getFullYear();

    availableYears.push(currentYear);
    availableYears.push(currentYear + 1);

    const requestSite = availableSites.find(
        (aSite) => aSite.id === initialValues.siteId
    );

    const requestInvestmentType = availableInvestmentTypes.find(
        (aInvestmentType) =>
            aInvestmentType.id === initialValues.investmentTypeId
    );

    const requestDivision = availableDivisions.find(
        (aDivision) => aDivision.id === initialValues.divisionId
    );

    const requestFinanceType = availableFinanceTypes.find(
        (aFinanceType) => aFinanceType.id === initialValues.financeTypeId
    );

    const [selectedYear, setSelectedYear] = React.useState(
        initialValues.fiscalYear
    );

    const [selectedSite, setSelectedSite] = React.useState(requestSite);

    const [selectedInvestmentType, setSelectedInvestmentType] = React.useState(
        requestInvestmentType
    );

    const [selectedDivision, setSelectedDivision] =
        React.useState(requestDivision);

    const [selectedFinanceType, setSelectedFinanceType] =
        React.useState(requestFinanceType);

    const [selectedStartDate, handleStartDateChange] = React.useState(
        initialValues.startDate
    );

    const [selectedEndDate, handleEndDateChange] = React.useState(
        initialValues.endDate
    );

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Formik
                cx={{ width: "100%" }}
                initialValues={initialValues}
                validationSchema={validationSchema}
                disabled={disabled}
                validateOnChange={true}
                onSubmit={(values) => {
                    handleSubmit(values);
                }}
            >
                {(formik) => {
                    return (
                        <Form style={{ width: "100%" }} autoComplete="off">
                            <FormControl fullWidth component="fieldset">
                                <Box
                                    display="grid"
                                    gridTemplateColumns="repeat(12, 1fr)"
                                >
                                    <Box
                                        gridColumn="span 12"
                                        marginBottom={"17px"}
                                    >
                                        <span className="section">
                                            Site & Type
                                        </span>
                                    </Box>
                                    <Box
                                        gridColumn="span 12"
                                        display="grid"
                                        gridTemplateColumns="repeat(12, 1fr)"
                                        gridColumnGap={"16px"}
                                        marginBottom={"8px"}
                                    >
                                        <Box gridColumn="span 6">
                                            <span className="label">Site</span>
                                        </Box>
                                        <Box gridColumn="span 6">
                                            <span className="label">
                                                Division
                                            </span>
                                        </Box>
                                    </Box>
                                    <Box
                                        gridColumn="span 12"
                                        display="grid"
                                        gridTemplateColumns="repeat(12, 1fr)"
                                        gridColumnGap={"16px"}
                                        marginBottom={"19px"}
                                    >
                                        <Box gridColumn="span 6">
                                            <Autocomplete
                                                id="siteId"
                                                name="siteId"
                                                disableClearable
                                                options={availableSites}
                                                value={selectedSite}
                                                getOptionLabel={(option) =>
                                                    option.name
                                                }
                                                onChange={(e, option) => {
                                                    setSelectedSite(option);

                                                    formik.setFieldValue(
                                                        "siteId",
                                                        option !== null
                                                            ? option.id
                                                            : initialValues.siteId
                                                    );
                                                }}
                                                renderInput={(params) => (
                                                    <StyledMUITextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        name="site"
                                                        {...params}
                                                    />
                                                )}
                                            />
                                        </Box>
                                        <Box gridColumn="span 6">
                                            <Autocomplete
                                                id="divisionId"
                                                name="divisionId"
                                                disableClearable
                                                options={availableDivisions}
                                                value={selectedDivision}
                                                getOptionLabel={(option) =>
                                                    option.name
                                                }
                                                onChange={(e, option) => {
                                                    setSelectedDivision(option);

                                                    formik.setFieldValue(
                                                        "divisionId",
                                                        option !== null
                                                            ? option.id
                                                            : initialValues.divisionId
                                                    );
                                                }}
                                                renderInput={(params) => (
                                                    <StyledMUITextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        name="divisionId"
                                                        {...params}
                                                    />
                                                )}
                                            />
                                        </Box>
                                    </Box>
                                    <Box
                                        gridColumn="span 12"
                                        marginBottom={"51px"}
                                    >
                                        <Box
                                            gridColumn="span 12"
                                            marginBottom={"8px"}
                                        >
                                            <span className="label">
                                                Investment type
                                            </span>
                                        </Box>

                                        <Autocomplete
                                            id="investmentTypeId"
                                            name="investmentTypeId"
                                            disableClearable
                                            options={availableInvestmentTypes}
                                            value={selectedInvestmentType}
                                            getOptionLabel={(option) =>
                                                option.name
                                            }
                                            onChange={(e, option) => {
                                                setSelectedInvestmentType(
                                                    option
                                                );

                                                formik.setFieldValue(
                                                    "investmentTypeId",
                                                    option !== null
                                                        ? option.id
                                                        : initialValues.investmentTypeId
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <StyledMUITextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    name="investmentType"
                                                    {...params}
                                                />
                                            )}
                                        />
                                    </Box>
                                    <Box
                                        gridColumn="span 12"
                                        marginBottom={"21px"}
                                    >
                                        <span className="section">
                                            Capex details
                                        </span>
                                    </Box>
                                    <Box
                                        gridColumn="span 12"
                                        marginBottom={"8px"}
                                    >
                                        <span className="label">
                                            Name of project
                                        </span>
                                    </Box>
                                    <Box
                                        gridColumn="span 12"
                                        marginBottom={"22px"}
                                    >
                                        <CustomFastField
                                            required
                                            variant="outlined"
                                            id="name"
                                            name="name"
                                            component={StyledTextField}
                                        />
                                    </Box>
                                    <Box
                                        gridColumn="span 12"
                                        marginBottom={"8px"}
                                    >
                                        <span className="label">Amount</span>
                                    </Box>
                                    <Box
                                        gridColumn="span 12"
                                        marginBottom={"22px"}
                                    >
                                        <CustomFastField
                                            required
                                            variant="outlined"
                                            id="amount"
                                            name="amount"
                                            component={CurrencyField}
                                        />
                                    </Box>
                                    <Box
                                        gridColumn="span 12"
                                        display="grid"
                                        gridTemplateColumns="repeat(12, 1fr)"
                                        gridColumnGap={"16px"}
                                        marginBottom={"8px"}
                                    >
                                        <Box gridColumn="span 6">
                                            <span className="label">
                                                Start date
                                            </span>
                                        </Box>
                                        <Box gridColumn="span 6">
                                            <span className="label">
                                                End date
                                            </span>
                                        </Box>
                                    </Box>
                                    <Box
                                        gridColumn="span 12"
                                        display="grid"
                                        gridTemplateColumns="repeat(12, 1fr)"
                                        gridColumnGap={"16px"}
                                        marginBottom={"22px"}
                                    >
                                        <Box gridColumn="span 6">
                                            <Field
                                                name="startDate"
                                                component={StyledDatePicker}
                                                format="yyyy/MM/dd"
                                                label=""
                                                value={selectedStartDate}
                                                onChange={(aDate) => {
                                                    handleStartDateChange(
                                                        aDate
                                                    );
                                                    formik.setFieldValue(
                                                        "startDate",
                                                        aDate
                                                    );
                                                }}
                                            />
                                        </Box>
                                        <Box gridColumn="span 6">
                                            <Field
                                                name="endDate"
                                                component={StyledDatePicker}
                                                format="yyyy/MM/dd"
                                                label=""
                                                value={selectedEndDate}
                                                onChange={(aDate) => {
                                                    handleEndDateChange(aDate);
                                                    formik.setFieldValue(
                                                        "endDate",
                                                        aDate
                                                    );
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                    <Box
                                        gridColumn="span 12"
                                        marginBottom={"8px"}
                                    >
                                        <span className="label">
                                            Finance options
                                        </span>
                                    </Box>
                                    <Box
                                        gridColumn="span 12"
                                        marginBottom={"22px"}
                                    >
                                        <Autocomplete
                                            id="financeTypeId"
                                            name="financeTypeId"
                                            disableClearable
                                            options={availableFinanceTypes}
                                            value={selectedFinanceType}
                                            getOptionLabel={(option) =>
                                                option.name
                                            }
                                            onChange={(e, option) => {
                                                setSelectedFinanceType(option);

                                                formik.setFieldValue(
                                                    "financeTypeId",
                                                    option !== null
                                                        ? option.id
                                                        : initialValues.financeTypeId
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <StyledMUITextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    name="financeTypeId"
                                                    {...params}
                                                />
                                            )}
                                        />
                                    </Box>
                                    <Box
                                        gridColumn="span 12"
                                        marginBottom={"15px"}
                                    >
                                        <span className="label">
                                            Budgeted request
                                        </span>
                                    </Box>
                                    <Box
                                        gridColumn="span 12"
                                        marginBottom={"20px"}
                                    >
                                        <FormControl component="fieldset">
                                            <RadioGroup
                                                value={formik.values.budgeted.toString()}
                                                onChange={(event) => {
                                                    formik.setFieldValue(
                                                        "budgeted",
                                                        event.currentTarget
                                                            .value
                                                    );
                                                }}
                                            >
                                                <FormControlLabel
                                                    control={<StyledRadio />}
                                                    value={"true"}
                                                    label="Budgeted"
                                                />
                                                <FormControlLabel
                                                    control={<StyledRadio />}
                                                    value={"false"}
                                                    label="Non-budgeted"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Box>
                                    <Box
                                        gridColumn="span 12"
                                        marginBottom={"8px"}
                                    >
                                        <span className="label">
                                            Fiscal year
                                        </span>
                                    </Box>
                                    <Box
                                        gridColumn="span 12"
                                        marginBottom={"22px"}
                                    >
                                        <Autocomplete
                                            id="fiscalYear"
                                            name="fiscalYear"
                                            disableClearable
                                            options={availableYears}
                                            value={selectedYear}
                                            getOptionLabel={(option) =>
                                                option.toString()
                                            }
                                            onChange={(e, option) => {
                                                setSelectedYear(option);

                                                formik.setFieldValue(
                                                    "fiscalYear",
                                                    option !== null
                                                        ? option
                                                        : initialValues.fiscalYear
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <StyledMUITextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    name="fiscalYear"
                                                    {...params}
                                                />
                                            )}
                                        />
                                    </Box>
                                    <Box
                                        gridColumn="span 12"
                                        marginBottom={"8px"}
                                    >
                                        <span className="label">
                                            File attachment
                                        </span>
                                    </Box>
                                    <Box gridColumn="span 12">
                                        {formik.values.file && (
                                            <React.Fragment>
                                                <span className="input">
                                                    {formik.values.file.name}
                                                </span>{" "}
                                                <IconButton
                                                    aria-label="delete"
                                                    color="primary"
                                                    onClick={() => {
                                                        formik.setFieldValue(
                                                            "file",
                                                            null
                                                        );
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </React.Fragment>
                                        )}
                                    </Box>
                                    {formik.touched.file &&
                                        formik.errors.file && (
                                            <div className="form-error">
                                                {formik.errors.file}
                                            </div>
                                        )}
                                    <Box
                                        gridColumn="span 12"
                                        marginBottom={"40px"}
                                    >
                                        <FileUploadButton
                                            fullWidth
                                            disableElevation
                                            disableFocusRipple
                                            disableRipple
                                            variant="contained"
                                            component="label"
                                            startIcon={
                                                <img src={FileUploadImage} />
                                            }
                                        >
                                            Upload File
                                            <input
                                                id="file"
                                                name="file"
                                                type="file"
                                                hidden
                                                onChange={(event) => {
                                                    formik.setFieldValue(
                                                        "file",
                                                        event.currentTarget
                                                            .files[0]
                                                    );
                                                }}
                                            />
                                        </FileUploadButton>
                                    </Box>
                                    <Box gridColumn="span 12">
                                        {isEditing ? (
                                            <ButtonGroup
                                                color="primary"
                                                fullWidth
                                                disableElevation
                                                disableFocusRipple
                                                disableRipple
                                            >
                                                <SubmitButton
                                                    fullWidth
                                                    disableElevation
                                                    disableFocusRipple
                                                    disableRipple
                                                    type="submit"
                                                    variant="contained"
                                                    size="large"
                                                    disabled={props.disabled}
                                                    style={
                                                        props.disabled
                                                            ? {
                                                                  backgroundColor:
                                                                      "#777",
                                                                  color: "#FFF",
                                                              }
                                                            : {
                                                                  backgroundColor:
                                                                      "#393939",
                                                                  color: "#FFF",
                                                              }
                                                    }
                                                >
                                                    <span className="cta">
                                                        Submit
                                                    </span>
                                                </SubmitButton>
                                                {enableDelete ? (
                                                    <Button
                                                        variant="contained"
                                                        style={{
                                                            backgroundColor:
                                                                "#C72D2D",
                                                        }}
                                                        onClick={() => {
                                                            handleDeleteConfirm(
                                                                formik.values.id
                                                            );
                                                        }}
                                                    >
                                                        <span className="bold-link-confirm">
                                                            Confirm delete
                                                        </span>
                                                        <img
                                                            src={DeleteImage}
                                                        />
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        variant="text"
                                                        onClick={() => {
                                                            handleDelete(
                                                                formik.values.id
                                                            );
                                                        }}
                                                    >
                                                        <span className="bold-link">
                                                            Delete request
                                                        </span>
                                                        <img
                                                            src={DeleteImage}
                                                        />
                                                    </Button>
                                                )}
                                            </ButtonGroup>
                                        ) : (
                                            <ButtonGroup
                                                color="primary"
                                                fullWidth
                                                disableElevation
                                                disableFocusRipple
                                                disableRipple
                                            >
                                                <SubmitButton
                                                    fullWidth
                                                    disableElevation
                                                    disableFocusRipple
                                                    disableRipple
                                                    type="submit"
                                                    variant="contained"
                                                    size="large"
                                                    disabled={props.disabled}
                                                    style={
                                                        props.disabled
                                                            ? {
                                                                  backgroundColor:
                                                                      "#777",
                                                                  color: "#FFF",
                                                              }
                                                            : {
                                                                  backgroundColor:
                                                                      "#393939",
                                                                  color: "#FFF",
                                                              }
                                                    }
                                                >
                                                    <span className="cta">
                                                        Submit
                                                    </span>
                                                </SubmitButton>
                                            </ButtonGroup>
                                        )}
                                    </Box>
                                </Box>
                            </FormControl>
                        </Form>
                    );
                }}
            </Formik>
        </MuiPickersUtilsProvider>
    );
};

export default RequestForm;
