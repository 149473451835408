import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import styled from "styled-components";
import { Link } from "react-router-dom";
import DataTemplate from "./../../components/DataTemplate.jsx";
import AdminTemplate from "./../admin/template";
import { protectedResources } from "./../../authConfig";
import Status from "./../../components/Status";
import ViewLinkImage from "./../../img/view-link.png";
import formatDate from "./../../utils/formatDate";
import { StyledMUITextField } from "./../../components/Form";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import { ColumnContainer } from "../../components/Layout.jsx";

const styles = (theme) => ({
    table: {
        minWidth: 800,
    },
    firstCell: {
        minWidth: "250px",
    },
});

const ViewLink = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const ViewLinkText = styled.span`
    margin-right: 8px;
`;

const Requests = (props) => {
    const { classes } = props;
    const [data, setData] = React.useState();
    const url = protectedResources.apiCapex.endpoint + "/report/request";
    const [investmentType, setInvestmentType] = React.useState(null);
    const [investmentTypeOptions, setinvestmentTypeOptions] = React.useState(
        []
    );
    const [siteOptions, setSiteOptions] = React.useState([]);
    const [site, setSite] = React.useState(null);
    const [fiscalYearOptions, setFiscalYearOptions] = React.useState([]);
    const [fiscalYear, setFiscalYear] = React.useState(null);

    const filteredRequests = data
        ? data.requests.filter((request) => {
              return (
                  (site === null || request.site.id == site.id) &&
                  (investmentType === null ||
                      request.investmentType.id == investmentType.id) &&
                  (fiscalYear === null || request.fiscalYear === fiscalYear)
              );
          })
        : [];

    return (
        <AdminTemplate>
            <DataTemplate
                {...props}
                url={url}
                data={data}
                setData={(result) => {
                    setSiteOptions(result.siteOptions);
                    setFiscalYearOptions(result.fiscalYearOptions);
                    setinvestmentTypeOptions(result.investmentTypeOptions);
                    setData(result);
                }}
            >
                <ColumnContainer>
                    <Box gridColumn="span 12" marginBottom={"5px"}>
                        <span className="headline-15-caps">Admin</span>
                    </Box>
                    <Box marginBottom={"34px"}>
                        <span className="headline-l">Capex requests</span>
                    </Box>
                    <Box
                        gridColumn="span 12"
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        marginBottom={"40px"}
                    >
                        <Box
                            gridColumn="span 9"
                            display="grid"
                            gridTemplateColumns="repeat(12, 1fr)"
                            gridColumnGap={10}
                        >
                            <Box
                                gridColumn="span 12"
                                display="grid"
                                gridTemplateColumns="repeat(12, 1fr)"
                                gridColumnGap={10}
                                marginBottom={"10px"}
                            >
                                <Box gridColumn="span 4">
                                    <span className="label">Site</span>
                                </Box>
                                <Box gridColumn="span 4">
                                    <span className="label">
                                        Investment types
                                    </span>
                                </Box>
                                <Box gridColumn="span 4">
                                    <span className="label">Fiscal year</span>
                                </Box>
                            </Box>

                            <Box gridColumn="span 4">
                                <Autocomplete
                                    id="site"
                                    name="site"
                                    openOnFocus
                                    value={site}
                                    options={siteOptions}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(e, option) => {
                                        setSite(option);
                                    }}
                                    renderInput={(params) => (
                                        <StyledMUITextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            name="site"
                                            {...params}
                                        />
                                    )}
                                />
                            </Box>
                            <Box gridColumn="span 4">
                                <Autocomplete
                                    id="investmentType"
                                    name="investmentType"
                                    options={investmentTypeOptions}
                                    value={investmentType}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(e, option) => {
                                        setInvestmentType(option);
                                    }}
                                    renderInput={(params) => (
                                        <StyledMUITextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            name="investmentType"
                                            {...params}
                                        />
                                    )}
                                />
                            </Box>
                            <Box gridColumn="span 4">
                                <Autocomplete
                                    id="fiscalYear"
                                    name="fiscalYear"
                                    options={fiscalYearOptions}
                                    value={fiscalYear}
                                    getOptionLabel={(option) =>
                                        option.toString()
                                    }
                                    onChange={(e, option) => {
                                        setFiscalYear(option);
                                    }}
                                    renderInput={(params) => (
                                        <StyledMUITextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            name="fiscalYear"
                                            {...params}
                                        />
                                    )}
                                />
                            </Box>
                        </Box>
                    </Box>
                    {data && (
                        <React.Fragment>
                            <TableContainer>
                                <Table
                                    aria-label="Requests"
                                    className={classes.table}
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <span className="label">
                                                    Date created
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                <span className="label">
                                                    Name of project
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                <span className="label">
                                                    Request ID
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                <span className="label">
                                                    Requested by
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                <span className="label">
                                                    Site
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                <span className="label">
                                                    Type of investment
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                <span className="label">
                                                    Fiscal year
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                <span className="label">
                                                    Amount
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                <span className="label">
                                                    Finance options
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                <span className="label">
                                                    Status
                                                </span>
                                            </TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data &&
                                            filteredRequests.map(
                                                (request, index) => (
                                                    <TableRow
                                                        style={
                                                            index % 2
                                                                ? {
                                                                      background:
                                                                          "none",
                                                                  }
                                                                : {
                                                                      background:
                                                                          "white",
                                                                  }
                                                        }
                                                        key={request.id}
                                                        sx={{
                                                            "&:last-child td, &:last-child th":
                                                                {
                                                                    border: 0,
                                                                },
                                                        }}
                                                    >
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                        >
                                                            <span className="input">
                                                                {formatDate(
                                                                    request.created
                                                                )}
                                                            </span>
                                                        </TableCell>
                                                        <TableCell>
                                                            <span className="input">
                                                                {request.name}
                                                            </span>
                                                        </TableCell>
                                                        <TableCell>
                                                            <span className="input">
                                                                {request.id}
                                                            </span>
                                                        </TableCell>
                                                        <TableCell>
                                                            {request.requester ? (
                                                                <span className="input">
                                                                    {
                                                                        request
                                                                            .requester
                                                                            .displayName
                                                                    }
                                                                </span>
                                                            ) : (
                                                                <Fragment>
                                                                    <span className="input">
                                                                        Requester
                                                                        account
                                                                        not
                                                                        found,
                                                                        id:
                                                                    </span>
                                                                    <br />
                                                                    <span className="input">
                                                                        {
                                                                            request.requesterId
                                                                        }
                                                                    </span>
                                                                </Fragment>
                                                            )}
                                                        </TableCell>
                                                        <TableCell>
                                                            {request.site && (
                                                                <span className="input">
                                                                    {
                                                                        request
                                                                            .site
                                                                            .name
                                                                    }
                                                                </span>
                                                            )}
                                                        </TableCell>
                                                        <TableCell>
                                                            {request.investmentType && (
                                                                <span className="input">
                                                                    {
                                                                        request
                                                                            .investmentType
                                                                            .name
                                                                    }
                                                                </span>
                                                            )}
                                                        </TableCell>
                                                        <TableCell>
                                                            <span className="input">
                                                                {
                                                                    request.fiscalYear
                                                                }
                                                            </span>
                                                        </TableCell>
                                                        <TableCell>
                                                            <span className="input">
                                                                {request.amount}{" "}
                                                            </span>
                                                            <span className="unit">
                                                                kEUR
                                                            </span>
                                                        </TableCell>
                                                        <TableCell>
                                                            <span className="input">
                                                                {request.financeType &&
                                                                    request
                                                                        .financeType
                                                                        .name}
                                                            </span>
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                <Status
                                                                    status={
                                                                        request.status
                                                                    }
                                                                />
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            <Link
                                                                to={
                                                                    "/request/" +
                                                                    request.id
                                                                }
                                                            >
                                                                <ViewLink>
                                                                    <ViewLinkText className="headline-15-black">
                                                                        view
                                                                    </ViewLinkText>
                                                                    <img
                                                                        src={
                                                                            ViewLinkImage
                                                                        }
                                                                    />
                                                                </ViewLink>
                                                            </Link>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </React.Fragment>
                    )}
                </ColumnContainer>
            </DataTemplate>
        </AdminTemplate>
    );
};

export default withStyles(styles)(Requests);
