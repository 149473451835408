import CssBaseline from "@material-ui/core/CssBaseline";
import React from "react";
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { ThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter } from "react-router-dom";
import styled from "styled-components";
import { loginRequest } from "./authConfig.js";
import theme from "./theme.jsx";
import Routes from "./Routes.jsx";
import ErrorComponent from "./components/Error.jsx";
import LoadingComponent from "./components/Loading.jsx";
import { EventType, InteractionType } from "@azure/msal-browser";

const Container = styled.div`
    overflow: hidden;
    height: 100%;
`;

const App = ({ instance }) => {
    const [stateUrl, setStateUrl] = React.useState("");

    instance.addEventCallback((event) => {
        switch (event.eventType) {
            case EventType.LOGIN_SUCCESS:
            case EventType.ACQUIRE_TOKEN_SUCCESS:
                if (event.interactionType === InteractionType.Redirect) {
                    setStateUrl(event.payload.state);
                }
        }
    });

    const authRequest = {
        ...loginRequest,
        state: window.location.pathname + window.location.search,
    };

    return (
        <MsalProvider instance={instance}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Container>
                    <MsalAuthenticationTemplate
                        interactionType={InteractionType.Redirect}
                        authenticationRequest={authRequest}
                        errorComponent={ErrorComponent}
                        loadingComponent={LoadingComponent}
                    >
                        <BrowserRouter>
                            <Routes stateUrl={stateUrl} />
                        </BrowserRouter>
                    </MsalAuthenticationTemplate>
                </Container>
            </ThemeProvider>
        </MsalProvider>
    );
};

export default App;
