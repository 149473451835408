import React, { useEffect, useImperativeHandle } from "react";
import { useHistory } from "react-router-dom";
import Template from "./Template.jsx";
import { capexApiGet } from "../fetch";
import LoadingComponent from "./Loading.jsx";
import ErrorComponent from "./Error.jsx";

const loadData = async (url, setData) => {
    const response = await capexApiGet(url);
    setData(response);
};

const DataTemplate = React.forwardRef((props, ref) => {
    const history = useHistory();
    const { url, error, CustomTemplate, children, data, setData } = props;

    useEffect(() => {
        loadData(url, onDataResult);
    }, []);

    useImperativeHandle(ref, () => ({
        loadData() {
            loadData(url, onDataResult);
        },
    }));

    const onDataResult = async (response) => {
        if (response) {
            if (response.status && response.type) {
                history.replace("/" + response.status);
                return;
            }

            setData(response);
        }
    };

    let content = children;

    const TemplateComponent = CustomTemplate ? CustomTemplate : Template;

    if (!data) {
        return <LoadingComponent />;
    }

    if (error) {
        return <ErrorComponent error={error} />;
    }

    return <TemplateComponent {...props}>{content}</TemplateComponent>;
});

export default DataTemplate;
