import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AdminTemplate from "./../admin/template";
import DataTemplate from "./../../components/DataTemplate.jsx";
import ExportLink from "./../../components/ExportLink";
import styled from "styled-components";
import { protectedResources } from "./../../authConfig";
import RemainingBudget from "../../components/RemainingBudget.jsx";
import { capexApiGet, downloadSummaryReport } from "../../fetch";
import {
    StyledMUITextField,
    StyledBox,
    SubmitButton,
} from "./../../components/Form";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    ButtonGroup,
} from "@material-ui/core";
import { ColumnContainer } from "../../components/Layout.jsx";

const Section = styled.div`
    border: 1px solid;
    border-color: #a4aeb7;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 20px;
`;

const CapexSummary = (props) => {
    const [data, setData] = React.useState();
    const [options, setOptions] = React.useState();
    const [isFetching, setIsFetching] = React.useState(false);
    const [isExporting, setIsExporting] = React.useState(false);
    const capexUrl = protectedResources.apiCapex.endpoint + "/report/capex";
    const exportUrl =
        protectedResources.apiCapex.endpoint + "/report/exportSummary";
    const capexOptionsUrl =
        protectedResources.apiCapex.endpoint + "/report/capexOptions";
    const [budgetedOptions, setBudgetedOptions] = React.useState([]);
    const [budgetedOption, setBudgetedOption] = React.useState(0);
    const currentYear = new Date().getFullYear();
    const [groupByOptions, setGroupByOptions] = React.useState([]);
    const [groupBy, setGroupBy] = React.useState(null);
    const [fiscalYearOptions, setFiscalYearOptions] = React.useState([]);
    const [fiscalYear, setFiscalYear] = React.useState(currentYear);

    const onHandleSubmit = async () => {
        setData(null);
        setIsFetching(true);

        const response = await capexApiGet(
            capexUrl + "/" + groupBy + "/" + fiscalYear + "/" + budgetedOption
        );

        setData(response);
        setIsFetching(false);
    };

    const exportSummaryReport = async () => {
        setIsExporting(true);

        const url =
            exportUrl + "/" + groupBy + "/" + fiscalYear + "/" + budgetedOption;

        await downloadSummaryReport(url);

        setIsExporting(false);
    };

    let budgetedOptionText = "";

    switch (budgetedOption) {
        case 0:
            budgetedOptionText = "Budgeted & Non-budgeted";
            break;
        case 1:
            budgetedOptionText = "Budgeted";
            break;
        case 2:
            budgetedOptionText = "Non-budgeted";
            break;
    }

    return (
        <AdminTemplate>
            <DataTemplate
                {...props}
                url={capexOptionsUrl}
                data={options}
                setData={(result) => {
                    setGroupByOptions(result.groupByOptions);
                    setGroupBy(result.groupByOptions[0]);
                    setFiscalYearOptions(result.fiscalYearOptions);
                    setBudgetedOptions(result.budgetedOptions);
                    setOptions(result);
                }}
            >
                <ColumnContainer>
                    <Box gridColumn="span 12" marginBottom={"5px"}>
                        <span className="headline-15-caps">Admin</span>
                    </Box>
                    <Box marginBottom={"10px"}>
                        <span className="headline-l">
                            Report - Capex summary
                        </span>
                    </Box>
                    <Box marginBottom={"34px"}>
                        <ExportLink
                            disabled={isExporting}
                            onClick={() => {
                                exportSummaryReport();
                            }}
                        />
                    </Box>
                    <Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gridColumnGap={10}
                        marginBottom={"40px"}
                    >
                        <Box gridColumn="span 9">
                            <Section>
                                <Box
                                    display="grid"
                                    gridTemplateColumns="repeat(12, 1fr)"
                                    gridColumnGap={10}
                                >
                                    <StyledBox gridColumn="span 12">
                                        <span className="headline-s">
                                            Report settings
                                        </span>
                                    </StyledBox>
                                    <StyledBox gridColumn="span 12">
                                        <div className="height-20" />
                                    </StyledBox>
                                    <StyledBox gridColumn="span 3">
                                        <span className="label">Group by</span>
                                    </StyledBox>
                                    <StyledBox gridColumn="span 3">
                                        <span className="label">
                                            Fiscal year
                                        </span>
                                    </StyledBox>
                                    <StyledBox gridColumn="span 3">
                                        <span className="label">
                                            Budgeted requests
                                        </span>
                                    </StyledBox>
                                    <StyledBox gridColumn="span 3" />
                                    <StyledBox gridColumn="span 3">
                                        <Autocomplete
                                            id="groupBy"
                                            name="groupBy"
                                            openOnFocus
                                            disableClearable
                                            value={groupBy}
                                            options={groupByOptions}
                                            getOptionLabel={(option) => option}
                                            onChange={(e, option) => {
                                                setGroupBy(option);
                                            }}
                                            renderInput={(params) => (
                                                <StyledMUITextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    name="fiscalYear"
                                                    {...params}
                                                />
                                            )}
                                        />
                                    </StyledBox>
                                    <StyledBox gridColumn="span 3">
                                        <Autocomplete
                                            id="fiscalYear"
                                            name="fiscalYear"
                                            disableClearable
                                            options={fiscalYearOptions}
                                            value={fiscalYear}
                                            getOptionLabel={(option) =>
                                                option === -1
                                                    ? "All"
                                                    : option.toString()
                                            }
                                            onChange={(e, option) => {
                                                setFiscalYear(option);
                                            }}
                                            renderInput={(params) => (
                                                <StyledMUITextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    name="fiscalYear"
                                                    {...params}
                                                />
                                            )}
                                        />
                                    </StyledBox>
                                    <StyledBox gridColumn="span 3">
                                        <Autocomplete
                                            id="budgetedRequests"
                                            name="budgetedRequests"
                                            disableClearable
                                            options={budgetedOptions}
                                            value={budgetedOption}
                                            getOptionLabel={(option) => {
                                                switch (option) {
                                                    case 0:
                                                        return "All";
                                                    case 1:
                                                        return "Budgeted";
                                                    case 2:
                                                        return "Non-budgeted";
                                                }
                                            }}
                                            onChange={(e, option) => {
                                                setBudgetedOption(option);
                                            }}
                                            renderInput={(params) => (
                                                <StyledMUITextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    name="budgeted"
                                                    {...params}
                                                />
                                            )}
                                        />
                                    </StyledBox>
                                    <StyledBox gridColumn="span 3">
                                        <ButtonGroup
                                            color="primary"
                                            fullWidth
                                            disableElevation
                                            disableFocusRipple
                                            disableRipple
                                            style={{ paddingLeft: "42px" }}
                                        >
                                            <SubmitButton
                                                variant="contained"
                                                size="large"
                                                disabled={isFetching}
                                                onClick={() => {
                                                    onHandleSubmit();
                                                }}
                                                style={{
                                                    backgroundColor: "#393939",
                                                    color: "#FFF",
                                                }}
                                            >
                                                {isFetching ? (
                                                    <CircularProgress />
                                                ) : (
                                                    <span className="cta">
                                                        Generate report
                                                    </span>
                                                )}
                                            </SubmitButton>
                                        </ButtonGroup>
                                    </StyledBox>
                                </Box>
                            </Section>
                        </Box>
                        <Box gridColumn="span 3" />
                    </Box>
                    {data && (
                        <React.Fragment>
                            <Box
                                display="grid"
                                gridTemplateColumns="repeat(12, 1fr)"
                                gridColumnGap={10}
                            >
                                <Box gridColumn="span 12">
                                    <span className="headline-s">
                                        {fiscalYear === -1
                                            ? "All fiscal years"
                                            : fiscalYear}{" "}
                                        | {budgetedOptionText}
                                    </span>
                                </Box>
                                <Box gridColumn="span 12">
                                    <div className="height-20" />
                                </Box>
                                <Box gridColumn="span 12">
                                    <span className="unit-spacing">
                                        By {groupBy}
                                    </span>
                                </Box>
                            </Box>
                            <TableContainer>
                                <Table
                                    sx={{ minWidth: 650 }}
                                    aria-label="Requests"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <span className="label">
                                                    Name
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                <span className="label">
                                                    Requested amount
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                <span className="label">
                                                    (# of requests)
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                <span className="label">
                                                    Pending
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                <span className="label">
                                                    Declined
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                <span className="label">
                                                    Approved
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                <span className="label">
                                                    Remaining non-budgeted
                                                </span>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.map(
                                            (
                                                {
                                                    name,
                                                    approvedAmount,
                                                    approvedCount,
                                                    declinedAmount,
                                                    declinedCount,
                                                    pendingAmount,
                                                    pendingCount,
                                                    remainingNonBudgeted,
                                                    requestCount,
                                                    totalAmount,
                                                },
                                                index
                                            ) => (
                                                <TableRow
                                                    key={name}
                                                    style={
                                                        index % 2
                                                            ? {
                                                                  background:
                                                                      "none",
                                                              }
                                                            : {
                                                                  background:
                                                                      "white",
                                                              }
                                                    }
                                                    sx={{
                                                        "&:last-child td, &:last-child th":
                                                            {
                                                                border: 0,
                                                            },
                                                    }}
                                                >
                                                    <TableCell>
                                                        <span className="input">
                                                            {name}
                                                        </span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span className="input">
                                                            {totalAmount}{" "}
                                                        </span>
                                                        <span className="unit">
                                                            kEUR
                                                        </span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span className="unit-blue">
                                                            ({requestCount})
                                                        </span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span className="input">
                                                            {pendingAmount}{" "}
                                                        </span>
                                                        <span className="unit">
                                                            kEUR
                                                        </span>
                                                        <span className="unit-blue">
                                                            ({pendingCount})
                                                        </span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span className="input">
                                                            {declinedAmount}{" "}
                                                        </span>
                                                        <span className="unit">
                                                            kEUR
                                                        </span>
                                                        <span className="unit-blue">
                                                            ({declinedCount})
                                                        </span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span className="input">
                                                            {approvedAmount}{" "}
                                                        </span>
                                                        <span className="unit">
                                                            kEUR
                                                        </span>
                                                        <span className="unit-blue">
                                                            ({approvedCount})
                                                        </span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <RemainingBudget
                                                            remainingBudget={
                                                                remainingNonBudgeted
                                                            }
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </React.Fragment>
                    )}
                </ColumnContainer>
            </DataTemplate>
        </AdminTemplate>
    );
};

export default CapexSummary;
