import React from "react";
import { Button, ButtonGroup } from "@material-ui/core";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ContentGuard } from "./../components/ContentGuard";
import logo from "./../img/hildinganderslogo-black.png";
import AppBar from "./../components/AppBar.jsx";
import Template from "./../components/Template.jsx";
import { capexApiGet } from "../fetch";
import { protectedResources } from "../authConfig";

const Index = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 56px;
`;

const Container = styled.div`
    display: flex;
    flex: 1;
`;

const Menu = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 104px;
`;

const Logo = styled.img`
    bottom: 0;
    right: 0;
    margin-right: 30px;
    position: absolute;
`;

const Component = (props) => {
    const [securityGroups, setSecurityGroups] = React.useState();
    const url = protectedResources.apiCapex.endpoint + "/groups/";

    const getGroups = async () => {
        const response = await capexApiGet(url);
        setSecurityGroups(response);
    };

    React.useEffect(() => {
        getGroups();
    }, []);

    return (
        <Container>
            <AppBar {...props} />
            {securityGroups && (
                <Template {...props}>
                    <Index>
                        <span className="overline-1">HILDING ANDERS</span>
                        <span className="headline-l">Capex request tool asdfdsafsadf</span>
                        <Menu>
                            <ButtonGroup
                                fullWidth
                                disableElevation
                                disableFocusRipple
                                disableRipple
                            >
                                <ContentGuard
                                    groups={[securityGroups.groupRequester]}
                                >
                                    <Button
                                        component={Link}
                                        className="index-button"
                                        to="/request"
                                        variant="contained"
                                        size="large"
                                    >
                                        Requester
                                    </Button>
                                </ContentGuard>
                                <ContentGuard
                                    groups={[securityGroups.groupApprover]}
                                >
                                    <Button
                                        component={Link}
                                        className="index-button"
                                        to="/approver/requests"
                                        variant="contained"
                                        size="large"
                                    >
                                        Approver
                                    </Button>
                                </ContentGuard>
                                <ContentGuard
                                    groups={[securityGroups.groupAdmin]}
                                >
                                    <Button
                                        component={Link}
                                        className="index-button"
                                        to="/admin"
                                        variant="contained"
                                        size="large"
                                    >
                                        Admin
                                    </Button>
                                </ContentGuard>
                            </ButtonGroup>
                        </Menu>
                        <Logo src={logo} />
                    </Index>
                </Template>
            )}
        </Container>
    );
};

export default Component;
