import { withStyles } from "@material-ui/core/styles";
import { Box, Button, Radio } from "@material-ui/core";
import MUITextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { TextField, DatePicker } from "material-ui-formik-components";
import Chip from "@material-ui/core/Chip";
import { FastField } from "formik";

export const SectionTitelBox = withStyles((theme) => ({
    root: {
        margin: "0px",
        marginBottom: "10px",
    },
}))(Box);

export const CustomFastField = ({ InputLabelProps = {}, ...props }) => {
    return <FastField label="" {...props} />;
};

export const CurrencyField = ({ InputLabelProps = {}, ...props }) => {
    return (
        <StyledTextField
            {...props}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">kEUR</InputAdornment>
                ),
            }}
        />
    );
};

export const StyledTextField = withStyles((theme) => ({
    root: {
        margin: 0,
        "& .MuiFormHelperText-root": {
            backgroundColor: "none",
            margin: 0,
            marginTop: "5px",
            marginLeft: "5px",
        },
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#FFF",
        },
    },
}))(TextField);

export const StyledBox = (props) => {
    return <Box {...props} className="styled-box" />;
};

export const StyledMUITextField = withStyles((theme) => ({
    root: {
        backgroundColor: "#FFF",
        margin: 0,
    },
}))(MUITextField);

export const StyledChip = withStyles((theme) => ({
    root: {
        backgroundColor: "#FFF",
        borderRadius: "unset",
        height: "39px",
        marginRight: "10px",
    },
}))(Chip);

export const SubmitButton = withStyles((theme) => ({
    root: {
        textTransform: "unset !important",
        height: "60px",
    },
}))(Button);

export const StyledDatePicker = withStyles((theme) => ({
    root: {
        backgroundColor: "#FFF",
        padding: "10px",
        margin: 0,
    },
}))(DatePicker);

export const FileUploadButton = withStyles((theme) => ({
    root: {
        backgroundColor: "#FFF",
        textTransform: "unset !important",
        height: "50px",
    },
}))(Button);

export const StyledRadio = withStyles((theme) => ({
    root: {
        backgroundColor: "#FFF",
        padding: 0,
        margin: "9px",
        color: "#5A9CD3",
        "&$checked": {
            color: "#5A9CD3",
        },
    },
    checked: {},
}))(Radio);
