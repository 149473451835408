import React from "react";
import styled from "styled-components";
import { Field } from "formik";
import { withStyles } from "@material-ui/core/styles";
import { protectedResources } from "../../authConfig";
import DataTemplate from "../../components/DataTemplate";
import AdminTemplate from "./template";
import DeleteImage from "./../../img/delete.png";
import { Box, Link } from "@material-ui/core";
import Form from "./form.jsx";
import { Container, ColumnLeft, Column } from "./../../components/Layout";

const StyledLabel = styled.label`
    padding-right: 5px;
    height: 10px;
`;

const StyledBox = withStyles((theme) => ({
    root: {
        margin: "4px",
        display: "flex",
        width: "100%",
        alignItems: "center",
    },
}))(Box);

const RemoveButton = withStyles((theme) => ({
    root: {
        marginLeft: "10px",
    },
}))(Link);

const AdminFinanceTypes = (props) => {
    const [data, setData] = React.useState();
    const url = protectedResources.apiCapex.endpoint + "/financeType";
    const initialValues = data && { items: data };
    const dataTemplateRef = React.useRef();

    const onHandleSubmit = async () => {
        dataTemplateRef.current.loadData();
    };

    return (
        <AdminTemplate>
            <DataTemplate
                {...props}
                url={url}
                data={data}
                setData={setData}
                ref={dataTemplateRef}
            >
                <Container>
                    <ColumnLeft>
                        <Box gridColumn="span 12" marginBottom={"5px"}>
                            <span className="headline-15-caps">Admin</span>
                        </Box>
                        <Box marginBottom={"10px"}>
                            <span className="headline-l">Finance types</span>
                        </Box>
                    </ColumnLeft>
                    <Column>
                        {data && (
                            <Form
                                url={url}
                                data={data}
                                initialValues={initialValues}
                                onHandleSubmit={() => {
                                    onHandleSubmit();
                                }}
                                makeNewItem={() => {
                                    return { name: "" };
                                }}
                                makeField={(item, index, fieldHelpers) => {
                                    return (
                                        <StyledBox
                                            key={index}
                                            gridColumn="span 12"
                                        >
                                            <StyledLabel
                                                htmlFor={`friends.${index}.name`}
                                            >
                                                Name
                                            </StyledLabel>
                                            <Field
                                                style={{
                                                    backgroundColor: "#FFF",
                                                    marginTop: "5px",
                                                    flex: 1,
                                                    fontSize: 14,
                                                    height: "35px",
                                                }}
                                                required
                                                variant="outlined"
                                                id="name"
                                                name={`items.${index}.name`}
                                            />
                                            <RemoveButton
                                                component="button"
                                                onClick={() => {
                                                    fieldHelpers.remove(index);
                                                }}
                                            >
                                                <img src={DeleteImage} />
                                            </RemoveButton>
                                        </StyledBox>
                                    );
                                }}
                            />
                        )}
                    </Column>
                </Container>
            </DataTemplate>
        </AdminTemplate>
    );
};

export default AdminFinanceTypes;
