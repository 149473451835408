import React, { useState } from "react";
import styled from "styled-components";
import Table from "@material-ui/core/Table";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { protectedResources } from "../../authConfig";
import {
    Box,
    TextField,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import AdminTemplate from "../admin/template";
import DataTemplate from "../../components/DataTemplate";
import EditLinkImage from "./../../img/edit-link.png";

const EditLink = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const EditLinkText = styled.span`
    margin-right: 8px;
`;

const StyledTextField = withStyles((theme) => ({
    root: {
        backgroundColor: "#FFF",
        marginTop: "8px",
    },
}))(TextField);

const ApproverIndex = (props) => {
    const [data, setData] = useState();
    const [selectedInvestmentType, setSelectedInvestmentType] =
        React.useState(null);

    const [selectedSite, setSelectedSite] = React.useState(null);

    const approvers = data?.approvers?.filter((approver) => {
        return (
            (selectedSite === null ||
                approver.sites.length === 0 ||
                approver.sites.some((site) => site.id == selectedSite.id)) &&
            (selectedInvestmentType === null ||
                approver.investmentTypes.length === 0 ||
                approver.investmentTypes.some(
                    (aType) => aType.id === selectedInvestmentType.id
                ))
        );
    });

    return (
        <AdminTemplate {...props}>
            <DataTemplate
                {...props}
                url={protectedResources.apiCapex.endpoint + "/approver"}
                data={data}
                setData={setData}
            >
                <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    marginBottom={"28px"}
                >
                    <Box gridColumn="span 12">
                        <span className="headline-15-caps">Admin</span>
                    </Box>
                    <Box gridColumn="span 12">
                        <span className="headline-l">Approvers</span>
                    </Box>
                </Box>
                {data && approvers?.length > 0 && (
                    <React.Fragment>
                        <Box
                            display="grid"
                            gridTemplateColumns="repeat(12, 1fr)"
                            gridColumnGap={16}
                            marginBottom={"26px"}
                        >
                            <Box gridColumn="span 3">
                                <span className="label">Site</span>
                            </Box>
                            <Box gridColumn="span 3">
                                <span className="label">
                                    Type of investment
                                </span>
                            </Box>
                            <Box gridColumn="span 6"></Box>
                            <Box gridColumn="span 3">
                                <Autocomplete
                                    id="sites"
                                    name="sites"
                                    openOnFocus
                                    value={selectedSite}
                                    options={data && data.availableSites}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(e, option) => {
                                        setSelectedSite(option);
                                    }}
                                    renderInput={(params) => (
                                        <StyledTextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            name="sites"
                                            {...params}
                                        />
                                    )}
                                />
                            </Box>
                            <Box gridColumn="span 3">
                                <Autocomplete
                                    id="investmentTypeId"
                                    name="investmentTypeId"
                                    openOnFocus
                                    value={selectedInvestmentType}
                                    options={
                                        data && data.availableInvestmentTypes
                                    }
                                    getOptionLabel={(option) => option.name}
                                    onChange={(e, option) => {
                                        setSelectedInvestmentType(option);
                                    }}
                                    renderInput={(params) => (
                                        <StyledTextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            name="investmentType"
                                            {...params}
                                        />
                                    )}
                                />
                            </Box>
                            <Box gridColumn="span 6"></Box>
                        </Box>
                        <TableContainer>
                            <Table
                                sx={{ minWidth: 650 }}
                                aria-label="Approvers"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <span className="label">Level</span>
                                        </TableCell>
                                        <TableCell>
                                            <span className="label">Role</span>
                                        </TableCell>
                                        <TableCell>
                                            <span className="label">
                                                Assigned user
                                            </span>
                                        </TableCell>
                                        <TableCell align="right">
                                            <span className="label">
                                                Non-budgeted
                                            </span>
                                        </TableCell>
                                        <TableCell align="right">
                                            <span className="label">
                                                Max anually
                                            </span>
                                        </TableCell>
                                        <TableCell align="right">
                                            <span className="label">
                                                Remaining non-budget
                                            </span>
                                        </TableCell>
                                        <TableCell align="right">
                                            <span className="label">
                                                Budgeted
                                            </span>
                                        </TableCell>
                                        <TableCell align="center">
                                            <span className="label">
                                                Active requests
                                            </span>
                                        </TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data &&
                                        approvers.map((approver, index) => (
                                            <TableRow
                                                key={approver.id}
                                                style={
                                                    index % 2
                                                        ? { background: "none" }
                                                        : {
                                                              background:
                                                                  "white",
                                                          }
                                                }
                                                sx={{
                                                    "&:last-child td, &:last-child th":
                                                        {
                                                            border: 0,
                                                        },
                                                }}
                                            >
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    <span className="input">
                                                        {approver.level}
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span className="input">
                                                        {approver.name}
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span className="input">
                                                        {approver.graphUserName}
                                                    </span>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <span className="input">
                                                        {approver.nonBudgeted}{" "}
                                                    </span>
                                                    <span className="unit">
                                                        kEUR
                                                    </span>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <span className="input">
                                                        {approver.maxAnually}{" "}
                                                    </span>
                                                    <span className="unit">
                                                        kEUR
                                                    </span>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <span className="input">
                                                        {approver.remaining}{" "}
                                                    </span>
                                                    <span className="unit">
                                                        kEUR
                                                    </span>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <span className="input">
                                                        {approver.budgeted}{" "}
                                                    </span>
                                                    <span className="unit">
                                                        kEUR
                                                    </span>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <span className="input">
                                                        {
                                                            approver
                                                                .activeRequests
                                                                .length
                                                        }
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <Link
                                                        to={
                                                            "/approver/" +
                                                            approver.id
                                                        }
                                                    >
                                                        <EditLink>
                                                            <EditLinkText className="headline-15-black">
                                                                View/Edit
                                                            </EditLinkText>
                                                            <img
                                                                src={
                                                                    EditLinkImage
                                                                }
                                                            />
                                                        </EditLink>
                                                    </Link>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </React.Fragment>
                )}
            </DataTemplate>
        </AdminTemplate>
    );
};

export default ApproverIndex;
