import React from "react";
import ReactDOM from "react-dom";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

import App from "./App.jsx";

import "./index.css";

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.enableAccountStorageEvents();

const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event) => {
    switch (event.eventType) {
        case EventType.LOGIN_SUCCESS:
            if (event.payload.account) {
                const account = event.payload.account;
                msalInstance.setActiveAccount(account);
            }
    }
});

ReactDOM.render(
    <App instance={msalInstance} />,
    document.getElementById("root")
);
