import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box,
} from "@material-ui/core";
import { protectedResources } from "../../authConfig";
import DataTemplate from "../../components/DataTemplate";
import AdminTemplate from "./template";
import EditLinkImage from "./../../img/edit-link.png";
import { ColumnContainer } from "../../components/Layout";

const EditLink = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const EditLinkText = styled.span`
    margin-right: 8px;
`;

const AdminTexts = (props) => {
    const [data, setData] = React.useState();
    const url = protectedResources.apiCapex.endpoint + "/text";

    return (
        <AdminTemplate>
            <DataTemplate {...props} url={url} data={data} setData={setData}>
                <ColumnContainer>
                    <Box gridColumn="span 12">
                        <span className="headline-15-caps">Admin</span>
                    </Box>
                    <Box gridColumn="span 12" marginBottom={"5px"}>
                        <span className="headline-l">Manage texts</span>
                    </Box>
                    <TableContainer>
                        <Table
                            sx={{ minWidth: 650 }}
                            aria-label="Active requests"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell width={"200px"}>
                                        <span className="label">Key</span>
                                    </TableCell>
                                    <TableCell>
                                        <span className="label">Text</span>
                                    </TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data &&
                                    data.map((text, index) => (
                                        <TableRow
                                            style={
                                                index % 2
                                                    ? {
                                                          background: "none",
                                                      }
                                                    : {
                                                          background: "white",
                                                      }
                                            }
                                            key={text.id}
                                            sx={{
                                                "&:last-child td, &:last-child th":
                                                    {
                                                        border: 0,
                                                    },
                                            }}
                                        >
                                            <TableCell width={"200px"}>
                                                <span className="input courier-bold">
                                                    {text.key}
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                <span className="input">
                                                    {'"'}
                                                    {text.value}
                                                    {'"'}
                                                </span>
                                            </TableCell>
                                            <TableCell width={"50px"}>
                                                <Link to={"/text/" + text.id}>
                                                    <EditLink>
                                                        <EditLinkText className="headline-15-black">
                                                            Edit
                                                        </EditLinkText>
                                                        <img
                                                            src={EditLinkImage}
                                                        />
                                                    </EditLink>
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ColumnContainer>
            </DataTemplate>
        </AdminTemplate>
    );
};

export default AdminTexts;
