import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 50px;
`;

export const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 50px;
`;

export const ColumnLeft = styled.div`
    flex: 1;
    margin-right: 154px;
    max-width: 536px;
`;

export const Column = styled.div`
    flex: 1;
    max-width: 536px;
`;

export const Section = styled.div`
    border: 1px solid;
    border-color: #a4aeb7;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin-bottom: 30px;
    padding-top: 31px;
    padding-left: 30px;
    padding-bottom: 35px;
`;

export const SectionWhiteBG = styled.div`
    border: 1px solid;
    border-color: #a4aeb7;
    background-color: #fff;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding-top: 33px;
    padding-left: 30px;
    padding-bottom: 34px;
    margin-bottom: 30px;
    display: flex;
`;

export const SectionCenterHorizontally = styled.div`
    border: 1px solid;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    justify-content: center;
`;

export const SectionCenter = styled.div`
    border: 1px solid;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const SectionWarning = styled.div`
    border: 1px solid;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px;
    display: flex;
`;
