import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import DataTemplate from "../../components/DataTemplate.jsx";
import RequestForm from "./form";
import RequestTemplate from "./template";
import { protectedResources } from "../../authConfig";
import {
    capexApiPost,
    capexApiPut,
    capexApiGet,
    capexApiDelete,
} from "../../fetch";
import History from "./history";
import {
    Container,
    ColumnLeft,
    Column,
    SectionWhiteBG,
} from "./../../components/Layout";
import { Box } from "@material-ui/core";
import Text from "../../components/Text.jsx";

const EditRequest = (props) => {
    const location = useLocation();
    const history = useHistory();
    const [data, setData] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [enableDelete, setEnableDelete] = React.useState(false);

    const handleSubmit = async (values) => {
        setIsSubmitting(true);

        values.amount = parseInt(values.amount);
        values.budgeted = values.budgeted === "true";

        const { file, ...otherValues } = values;

        if (file) {
            var body = new FormData();
            body.append("file", file);

            const fileUploadResponse = await capexApiPut(
                protectedResources.apiCapex.endpoint + "/file/",
                body
            );

            otherValues.fileName = fileUploadResponse.fileName;
            otherValues.fileDescription = fileUploadResponse.fileDescription;
        }

        const json = JSON.stringify(otherValues);

        await capexApiPost(
            protectedResources.apiCapex.endpoint + "/request/edit/",
            json
        );

        history.push("/request/");
    };

    const handleDelete = async (id) => {
        const result = await capexApiGet(
            protectedResources.apiCapex.endpoint + "/request/delete/" + id
        );

        if (result.success) {
            setEnableDelete(true);
        }
    };

    const handleDeleteConfirm = async (id) => {
        await capexApiDelete(
            protectedResources.apiCapex.endpoint +
                "/request/deleteConfirmed/" +
                id
        );

        history.push("/request");
    };

    const initialValues =
        data && data.request
            ? { ...data.request }
            : {
                  name: "",
                  amount: 0,
                  fiscalYear: new Date().getFullYear(),
                  startDate: "",
                  endDate: "",
                  budgeted: false,
                  siteId: 0,
                  investmentTypeId: 0,
                  divisionId: 0,
                  financeTypeId: 0,
                  investmentTypeId: 0,
                  requesterId: "",
                  file: "",
              };

    if (data) {
        initialValues.siteId =
            data.availableSites &&
            data.availableSites.length > 0 &&
            data.availableSites[0].id;
        initialValues.investmentTypeId =
            data.availableInvestmentTypes &&
            data.availableInvestmentTypes.length > 0 &&
            data.availableInvestmentTypes[0].id;
    }

    return (
        <RequestTemplate {...props}>
            <DataTemplate
                {...props}
                url={protectedResources.apiCapex.endpoint + location.pathname}
                data={data}
                setData={setData}
            >
                <Container>
                    <ColumnLeft>
                        <Box paddingBottom={"29px"}>
                            <span className="headline-l">
                                Capex request form
                            </span>
                        </Box>
                        <Box paddingBottom={"29px"}>
                            <span className="body-copy">
                                <Text id="request_edit_description" />
                            </span>
                        </Box>
                        {data && data.request && (
                            <React.Fragment>
                                <SectionWhiteBG>
                                    <History request={data.request} />
                                </SectionWhiteBG>
                            </React.Fragment>
                        )}
                    </ColumnLeft>
                    <Column>
                        <RequestForm
                            isEditing
                            data={data}
                            initialValues={initialValues}
                            handleSubmit={handleSubmit}
                            disabled={isSubmitting}
                            handleDelete={handleDelete}
                            handleDeleteConfirm={handleDeleteConfirm}
                            enableDelete={enableDelete}
                        ></RequestForm>
                    </Column>
                </Container>
            </DataTemplate>
        </RequestTemplate>
    );
};

export default EditRequest;
