import React, { useState } from "react";
import { Box, TextField, ButtonGroup } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { withStyles } from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/Cancel";
import ApproverTemplate from "./../approver/template";
import DataTemplate from "./../../components/DataTemplate";
import { useLocation, useHistory } from "react-router-dom";
import { protectedResources } from "./../../authConfig";
import StatusComponent from "./../../components/Status";
import { capexApiPost } from "../../fetch";
import { SubmitButton } from "./../../components/Form";
import CapexDetails from "./../../components/CapexDetails";
import WarningImage from "./../../img/warning-icon.png";
import History from "./history";
import {
    Container,
    ColumnLeft,
    Column,
    Section,
    SectionWhiteBG,
    SectionWarning,
} from "./../../components/Layout";
import RemainingBudget from "../../components/RemainingBudget";

const styles = (theme) => ({
    outlinedInput: {
        border: "1px solid",
        borderColor: "#A4AEB7",
        borderRadius: 0,
        backgroundColor: "#FFF !important",
    },
});

const ReviewRequest = (props) => {
    const { classes } = props;
    const [data, setData] = useState();
    const location = useLocation();
    const history = useHistory();
    const { request, requester, approver, remainingBudget } = data ? data : {};
    const [commentInput, setCommentInput] = React.useState("");
    const [approved, setApproved] = React.useState(null);
    const [budgetCoversAmount, setBudgetCoversAmount] = React.useState(false);
    const [submitDisabled, setSubmitDisabled] = React.useState(true);

    const handleSubmit = async () => {
        const request = {
            approved,
            comment: commentInput,
        };

        setSubmitDisabled(true);

        const json = JSON.stringify(request);

        await capexApiPost(
            protectedResources.apiCapex.endpoint + location.pathname,
            json
        );

        history.push("/approver/requests");
    };

    const getBudgetWarningElement = () => {
        if (!approved) return;

        if (!budgetCoversAmount) {
            return (
                <Box marginTop={"12px"}>
                    <SectionWarning>
                        <span className="radio-button centerV">
                            <img
                                src={WarningImage}
                                className="padding-right-11"
                            />
                            Insufficient budget, request will be transferred to
                            next level.
                        </span>
                    </SectionWarning>
                </Box>
            );
        }

        if (!request.budgeted) {
            return (
                <Box marginTop={"12px"}>
                    <SectionWarning>
                        <span className="radio-button center">
                            Remaining non-budget for fiscal year 2021 after
                            approved request:&nbsp;
                            <RemainingBudget
                                remainingBudget={
                                    remainingBudget - request.amount
                                }
                            />
                        </span>
                    </SectionWarning>
                </Box>
            );
        }
    };

    const budgetWarningElement = getBudgetWarningElement();

    return (
        <ApproverTemplate {...props}>
            <DataTemplate
                {...props}
                url={
                    protectedResources.apiCapex.endpoint +
                    location.pathname +
                    "/" +
                    props.actionId
                }
                data={data}
                setData={setData}
            >
                <Container>
                    <ColumnLeft>
                        <Box
                            display="grid"
                            gridTemplateColumns="repeat(12, 1fr)"
                        >
                            <Box gridColumn="span 12">
                                <span className="headline-15">Approver</span>
                            </Box>
                            <Box gridColumn="span 12" marginBottom={"5px"}>
                                <span className="headline-l">
                                    {request && request.name}
                                </span>
                            </Box>
                            <Box
                                gridColumn="span 12"
                                display="grid"
                                gridTemplateColumns="repeat(12, 1fr)"
                                marginBottom={"54px"}
                            >
                                <Box gridColumn="span 4">
                                    <span className="label">Requested by:</span>
                                </Box>
                                <Box gridColumn="span 8">
                                    <span className="label">
                                        Request status:
                                    </span>
                                </Box>
                                <Box gridColumn="span 4">
                                    <span className="input">
                                        {requester && requester.displayName}
                                    </span>
                                </Box>
                                <Box gridColumn="span 8">
                                    <span className="input">
                                        {request && (
                                            <StatusComponent
                                                status={request.status}
                                            />
                                        )}
                                    </span>
                                </Box>
                            </Box>
                        </Box>
                        {request && request.budgeted ? (
                            <Section>
                                <Box
                                    display="grid"
                                    gridTemplateColumns="repeat(12, 1fr)"
                                >
                                    <Box gridColumn="span 12">
                                        <span className="section">
                                            BUDGET (BUDGETED REQUESTS)
                                        </span>
                                    </Box>
                                    <Box gridColumn="span 12">
                                        <span className="headline-s">
                                            {approver && approver.name}
                                        </span>
                                    </Box>
                                    <Box
                                        marginTop={"22px"}
                                        gridColumn="span 12"
                                        display="grid"
                                        gridTemplateColumns="repeat(12, 1fr)"
                                    >
                                        <Box gridColumn="span 12">
                                            <span className="label">
                                                Budgeted
                                            </span>
                                        </Box>
                                    </Box>
                                    <Box
                                        marginTop={"12px"}
                                        gridColumn="span 12"
                                        display="grid"
                                        gridTemplateColumns="repeat(12, 1fr)"
                                    >
                                        <Box gridColumn="span 12">
                                            <span className="input">
                                                {approver && approver.budgeted}
                                            </span>
                                            <span className="unit"> kEUR</span>
                                        </Box>
                                    </Box>
                                </Box>
                            </Section>
                        ) : (
                            <Section>
                                <Box
                                    display="grid"
                                    gridTemplateColumns="repeat(12, 1fr)"
                                >
                                    <Box gridColumn="span 12">
                                        <span className="section">
                                            BUDGET (NON-BUDGETED REQUESTS)
                                        </span>
                                    </Box>
                                    <Box gridColumn="span 12">
                                        <span className="headline-s">
                                            {approver && approver.name}
                                        </span>
                                    </Box>
                                    <Box
                                        marginTop={"22px"}
                                        gridColumn="span 12"
                                        display="grid"
                                        gridTemplateColumns="repeat(12, 1fr)"
                                    >
                                        <Box gridColumn="span 3">
                                            <span className="label">
                                                Non-budgeted
                                            </span>
                                        </Box>
                                        <Box gridColumn="span 3">
                                            <span className="label">
                                                Max annually
                                            </span>
                                        </Box>
                                        <Box gridColumn="span 6">
                                            <span className="label">
                                                Remaining (non-budgeted)
                                            </span>
                                        </Box>
                                    </Box>
                                    <Box
                                        marginTop={"12px"}
                                        gridColumn="span 12"
                                        display="grid"
                                        gridTemplateColumns="repeat(12, 1fr)"
                                    >
                                        <Box gridColumn="span 3">
                                            <span className="input">
                                                {approver &&
                                                    approver.nonBudgeted}
                                            </span>
                                            <span className="unit"> kEUR</span>
                                        </Box>
                                        <Box gridColumn="span 3">
                                            <span className="input">
                                                {approver &&
                                                    approver.maxAnually}
                                            </span>
                                            <span className="unit"> kEUR</span>
                                        </Box>
                                        <Box
                                            gridColumn="span 6"
                                            className="display-flex"
                                        >
                                            <div
                                                className={
                                                    remainingBudget === 0
                                                        ? "budget-warning"
                                                        : ""
                                                }
                                            >
                                                <span
                                                    className={
                                                        remainingBudget === 0
                                                            ? "input color-white"
                                                            : "input"
                                                    }
                                                >
                                                    {remainingBudget}
                                                </span>
                                                <span
                                                    className={
                                                        remainingBudget === 0
                                                            ? "unit color-white"
                                                            : "unit"
                                                    }
                                                >
                                                    {" "}
                                                    kEUR
                                                </span>
                                            </div>
                                        </Box>
                                    </Box>
                                </Box>
                            </Section>
                        )}
                        <SectionWhiteBG>
                            <History request={request} />
                        </SectionWhiteBG>
                    </ColumnLeft>
                    <Column>
                        <CapexDetails request={request} />
                        <Box marginBottom={"8px"}>
                            <span className="section">REQUEST RESPONSE</span>
                        </Box>
                        <Box>
                            <Box marginBottom={"8px"}>
                                <span className="label">
                                    Message (optional)
                                </span>
                            </Box>
                            <Box marginBottom={"34px"}>
                                <TextField
                                    id="comment"
                                    variant="filled"
                                    multiline
                                    minRows={4}
                                    maxRows={4}
                                    className="commentTextField"
                                    value={commentInput}
                                    onChange={(event) => {
                                        setCommentInput(
                                            event.currentTarget.value
                                        );
                                    }}
                                    InputProps={{
                                        classes: {
                                            root: classes.outlinedInput,
                                        },
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box>
                            <Box marginBottom={"7px"}>
                                <span className="label">Approval decision</span>
                            </Box>
                            <Box>
                                <ButtonGroup
                                    color="primary"
                                    fullWidth
                                    disableElevation
                                    disableFocusRipple
                                    disableRipple
                                >
                                    <SubmitButton
                                        fullWidth
                                        disableElevation
                                        disableFocusRipple
                                        disableRipple
                                        style={
                                            approved === true
                                                ? {
                                                      backgroundColor:
                                                          "#608634",
                                                      color: "#FFF",
                                                      marginRight: "8px",
                                                  }
                                                : {
                                                      backgroundColor: "#FFF",
                                                      color: "#000",
                                                      marginRight: "8px",
                                                  }
                                        }
                                        startIcon={<CheckCircleIcon />}
                                        onClick={(event) => {
                                            setApproved(true);
                                            setSubmitDisabled(false);
                                            setBudgetCoversAmount(
                                                (request.budgeted &&
                                                    approver.budgeted >=
                                                        request.amount) ||
                                                    (remainingBudget >=
                                                        request.amount &&
                                                        approver.nonBudgeted >=
                                                            request.amount)
                                            );
                                        }}
                                    >
                                        <span
                                            className={
                                                approved
                                                    ? "cta"
                                                    : "cta color-393939"
                                            }
                                        >
                                            Approve request
                                        </span>
                                    </SubmitButton>
                                    <SubmitButton
                                        fullWidth
                                        disableElevation
                                        disableFocusRipple
                                        disableRipple
                                        style={
                                            approved === null ||
                                            approved === true
                                                ? {
                                                      backgroundColor: "#FFF",
                                                      color: "#000",
                                                  }
                                                : {
                                                      backgroundColor:
                                                          "#AA1C1C",
                                                      color: "#FFF",
                                                  }
                                        }
                                        startIcon={<CancelIcon />}
                                        onClick={(event) => {
                                            setApproved(false);
                                            setSubmitDisabled(false);
                                        }}
                                    >
                                        <span
                                            className={
                                                approved === null ||
                                                approved === true
                                                    ? "cta color-393939"
                                                    : "cta"
                                            }
                                        >
                                            Decline request
                                        </span>
                                    </SubmitButton>
                                </ButtonGroup>
                            </Box>
                        </Box>
                        {budgetWarningElement}
                        <Box marginTop={"25px"} marginBottom={"30px"}>
                            <ButtonGroup
                                color="primary"
                                fullWidth
                                disableElevation
                                disableFocusRipple
                                disableRipple
                            >
                                <SubmitButton
                                    fullWidth
                                    disableElevation
                                    disableFocusRipple
                                    disableRipple
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    disabled={submitDisabled}
                                    style={
                                        !submitDisabled
                                            ? {
                                                  backgroundColor: "#393939",
                                                  color: "#FFF",
                                              }
                                            : {
                                                  backgroundColor: "#777",
                                                  color: "#FFF",
                                              }
                                    }
                                    onClick={(event) => {
                                        handleSubmit();
                                    }}
                                >
                                    <span className="cta">Submit response</span>
                                </SubmitButton>
                            </ButtonGroup>
                        </Box>
                    </Column>
                </Container>
            </DataTemplate>
        </ApproverTemplate>
    );
};

export default withStyles(styles)(ReviewRequest);
