import React from "react";
import { Typography } from "@material-ui/core";
import Template from "./Template.jsx";

const ErrorComponent = ({ error }) => {
	console.log(error);

    return (
        <Template>
            <Typography variant="h6">
                An Error Occurred: {error.errorCode}
            </Typography>
        </Template>
    );
};

export default ErrorComponent;
