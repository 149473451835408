import React, { useState } from "react";
import DataTemplate from "./../../components/DataTemplate";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useMsal } from "@azure/msal-react";
import RequestTemplate from "./template";
import Status from "./../../components/Status";
import ViewLinkImage from "./../../img/view-link.png";
import formatDate from "./../../utils/formatDate";
import { protectedResources } from "./../../authConfig";
import { ColumnContainer } from "../../components/Layout";

const ViewLink = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const ViewLinkText = styled.span`
    margin-right: 8px;
`;

const RequestIndex = (props) => {
    const [data, setData] = useState();
    const { instance } = useMsal();
    const currentAccount = instance.getActiveAccount();

    const getLink = (request) => {
        return request.level === 0
            ? "/request/edit/" + request.id
            : "/request/" + request.id;
    };

    return (
        <RequestTemplate>
            <DataTemplate
                {...props}
                url={protectedResources.apiCapex.endpoint + "/request/all"}
                data={data}
                setData={setData}
            >
                <ColumnContainer>
                    <Box
                        display={"flex"}
                        flexDirection={"column"}
                        marginBottom={"25px"}
                    >
                        {currentAccount && (
                            <span className="headline-15-caps">
                                {currentAccount.name}
                            </span>
                        )}
                        <span className="headline-l">Your requests</span>
                    </Box>
                    {data && data.length > 0 && (
                        <React.Fragment>
                            <TableContainer>
                                <Table
                                    sx={{ minWidth: 650 }}
                                    aria-label="Requests"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <span className="label">
                                                    Date requested
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                <span className="label">
                                                    Name of project
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                <span className="label">
                                                    Request ID
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                <span className="label">
                                                    Site
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                <span className="label">
                                                    Type of investment
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                <span className="label">
                                                    Fiscal year
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                <span className="label">
                                                    Amount
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                <span className="label">
                                                    Status
                                                </span>
                                            </TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.map((request, index) => (
                                            <TableRow
                                                style={
                                                    index % 2
                                                        ? { background: "none" }
                                                        : {
                                                              background:
                                                                  "white",
                                                          }
                                                }
                                                key={request.id}
                                                sx={{
                                                    "&:last-child td, &:last-child th":
                                                        {
                                                            border: 0,
                                                        },
                                                }}
                                            >
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    <span className="input">
                                                        {formatDate(
                                                            request.created
                                                        )}
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span className="input">
                                                        {request.name}
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span className="input">
                                                        {request.id}
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    {request.site && (
                                                        <span className="input">
                                                            {request.site.name}
                                                        </span>
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {request.investmentType && (
                                                        <span className="input">
                                                            {
                                                                request
                                                                    .investmentType
                                                                    .name
                                                            }
                                                        </span>
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    <span className="input">
                                                        {request.fiscalYear}
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span className="input">
                                                        {request.amount}{" "}
                                                    </span>
                                                    <span className="unit">
                                                        kEUR
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        <Status
                                                            status={
                                                                request.status
                                                            }
                                                        />
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    <Link to={getLink(request)}>
                                                        <ViewLink>
                                                            <ViewLinkText className="headline-15-black">
                                                                View
                                                            </ViewLinkText>
                                                            <img
                                                                src={
                                                                    ViewLinkImage
                                                                }
                                                            />
                                                        </ViewLink>
                                                    </Link>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </React.Fragment>
                    )}
                </ColumnContainer>
            </DataTemplate>
        </RequestTemplate>
    );
};

export default RequestIndex;
