import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiLink from "@material-ui/core/Link";
import ExportImage from "./../img/export-link.png";

const Link = withStyles({
    root: {
        "&[disabled]": {
            color: "grey",
            cursor: "default",
            "&:hover": {
                textDecoration: "none",
            },
        },
    },
})(MuiLink);

const ExportLink = ({ disabled, onClick = () => {} }) => {
    return (
        <Link
            className="centerV cursor-pointer"
            disabled={disabled}
            component="button"
            onClick={() => {
                onClick();
            }}
        >
            <span className="download-link padding-right-10">
                Export (Excel)
            </span>
            <img src={ExportImage} />
        </Link>
    );
};

export default ExportLink;
