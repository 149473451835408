import React from "react";
import { capexApiGet } from "../fetch";
import { protectedResources } from "../authConfig";

const Text = (props) => {
    const [text, setText] = React.useState();
    const [isFetching, setIsFetching] = React.useState(true);
    const url = protectedResources.apiCapex.endpoint + "/text/key/" + props.id;

    const getText = async () => {
        setText(null);
        setIsFetching(true);

        const response = await capexApiGet(url);

        setText(response);
        setIsFetching(false);
    };

    React.useEffect(() => {
        getText();
    }, []);

    return (
        <React.Fragment>
            {!isFetching && text && `${text.value}`}
        </React.Fragment>
    );
};

export default Text;
