import * as yup from "yup";
import React from "react";
import { Formik, Form, FieldArray } from "formik";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
    Box,
    ButtonGroup,
    Button,
    FormControl,
    MenuItem,
    Select,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import DeleteImage from "./../../img/delete.png";
import InfoImage from "./../../img/info.png";
import {
    CustomFastField,
    CurrencyField,
    StyledTextField,
    StyledMUITextField,
    StyledChip,
    SubmitButton,
} from "./../../components/Form";

const validationSchema = yup.object({}).shape({
    name: yup
        .string()
        .min(2, "Too short")
        .max(100, "Too long")
        .required("Required"),
    level: yup
        .number()
        .moreThan(0, "Must be larger than 0")
        .required("Required"),
    budgeted: yup
        .number()
        .moreThan(-1, "Must be 0 or more.")
        .lessThan(1000000, "Must be less than 1000000.")
        .required("Required"),
    maxAnually: yup
        .number()
        .moreThan(-1, "Must be 0 or more.")
        .lessThan(1000000, "Must be less than 1000000.")
        .required("Required"),
    nonBudgeted: yup
        .number()
        .moreThan(-1, "Must be 0 or more.")
        .lessThan(1000000, "Must be less than 1000000.")
        .required("Required"),
    graphUserId: yup.string().required("Required"),
});

const OutlinedBox = styled.div`
    border: 1px solid;
    border-color: #9e9590;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    flex: 1;
    align-items: center;
    margin-top: 10px;
`;

const StyledImage = styled.img`
    margin-right: 5px;
`;

const BorderLeftDiv = styled.div`
    opacity: 0.2;
    border-left: 1px solid #707070;
    height: 56px;
`;

export const useStyles = makeStyles((theme) => ({
    fieldArray: {
        paddingTop: "10px",
        display: "flex",
        flexDirection: "row",
    },
    chips: {
        display: "flex",
        alignItems: "center",
        paddingLeft: "10px",
    },
}));

export const useMuiSelectStyles = makeStyles(
    {
        outlined: {
            padding: "10px",
            paddingRight: "10px !important",
            backgroundColor: "#FFF",
        },
    },
    { name: "MuiSelect" }
);

const ApproverForm = (props) => {
    const {
        initialValues,
        handleSubmit,
        handleDelete,
        enableDelete,
        handleDeleteConfirm,
        data,
        isEditing,
    } = props;
    const classes = useStyles();
    const selectClasses = useMuiSelectStyles();

    const { approver, availableSites, availableInvestmentTypes, graphUsers } =
        data;

    const selectedGraphUser = approver
        ? graphUsers.find(
              (aGraphUser) => aGraphUser.id === approver.graphUserId
          )
        : null;

    const [graphUser, setGraphUser] = React.useState(selectedGraphUser);

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Formik
                cx={{ width: "100%" }}
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnChange={false}
                onSubmit={(values) => {
                    handleSubmit(values);
                }}
            >
                {(formik) => {
                    const filteredSiteOptions = availableSites.filter(
                        (aAvailableSite) =>
                            !formik.values.sites.some(
                                (aValueSite) =>
                                    aAvailableSite.id === aValueSite.id
                            )
                    );

                    const filteredInvestmentTypeOptions =
                        availableInvestmentTypes.filter(
                            (aAvailableInvestmentType) =>
                                !formik.values.investmentTypes.some(
                                    (aValueInvestmentType) =>
                                        aAvailableInvestmentType.id ===
                                        aValueInvestmentType.id
                                )
                        );

                    const showChangedBudgedWarning =
                        props.isEditing &&
                        (formik.values.budgeted !== initialValues.budgeted ||
                            formik.values.nonBudgeted !==
                                initialValues.nonBudgeted ||
                            formik.values.maxAnnually !==
                                initialValues.maxAnnually);

                    return (
                        <Form style={{ width: "100%" }} autoComplete="off">
                            <FormControl fullWidth component="fieldset">
                                <Box
                                    display="grid"
                                    gridTemplateColumns="repeat(12, 1fr)"
                                >
                                    <Box
                                        gridColumn="span 12"
                                        marginBottom={"17px"}
                                    >
                                        <span className="section">
                                            Role & user
                                        </span>
                                    </Box>
                                    <Box
                                        gridColumn="span 12"
                                        marginBottom={"8px"}
                                    >
                                        <span className="label">
                                            Approver role name
                                        </span>
                                    </Box>
                                    <Box
                                        gridColumn="span 12"
                                        marginBottom={"19px"}
                                    >
                                        <CustomFastField
                                            required
                                            variant="outlined"
                                            id="name"
                                            name="name"
                                            component={StyledTextField}
                                        />
                                    </Box>
                                    <Box
                                        gridColumn="span 12"
                                        display="grid"
                                        gridTemplateColumns="repeat(12, 1fr)"
                                        gridColumnGap={"16px"}
                                        marginBottom={"8px"}
                                    >
                                        <Box gridColumn="span 3">
                                            <span className="label">
                                                Approver level
                                            </span>
                                        </Box>
                                        <Box gridColumn="span 9">
                                            <span className="label">
                                                Assigned user
                                            </span>
                                        </Box>
                                    </Box>
                                    <Box
                                        gridColumn="span 12"
                                        display="grid"
                                        gridTemplateColumns="repeat(12, 1fr)"
                                        gridColumnGap={"16px"}
                                        marginBottom={"51px"}
                                    >
                                        <Box gridColumn="span 3">
                                            <CustomFastField
                                                required
                                                variant="outlined"
                                                id="level"
                                                name="level"
                                                type="number"
                                                component={StyledTextField}
                                            />
                                        </Box>
                                        <Box gridColumn="span 9">
                                            <Autocomplete
                                                required
                                                id="graphUserId"
                                                name="graphUserId"
                                                options={graphUsers}
                                                value={graphUser}
                                                getOptionLabel={(option) =>
                                                    option.displayName
                                                }
                                                onChange={(e, option) => {
                                                    setGraphUser(option);

                                                    formik.setFieldValue(
                                                        "graphUserId",
                                                        option !== null
                                                            ? option.id
                                                            : initialValues.graphUserId
                                                    );
                                                }}
                                                renderInput={(params) => (
                                                    <StyledMUITextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        name="graphUserId"
                                                        {...params}
                                                    />
                                                )}
                                            />
                                            {formik.touched.graphUserId &&
                                                formik.errors.graphUserId && (
                                                    <div className="form-error">
                                                        {
                                                            formik.errors
                                                                .graphUserId
                                                        }
                                                    </div>
                                                )}
                                        </Box>
                                    </Box>
                                    <Box
                                        gridColumn="span 12"
                                        display="grid"
                                        gridTemplateColumns="repeat(12, 1fr)"
                                        marginBottom={"21px"}
                                        gridColumnGap={"16px"}
                                    >
                                        <Box gridColumn="span 9">
                                            <span className="section">
                                                Non-budgeted Limit
                                            </span>
                                        </Box>
                                        <Box gridColumn="span 3">
                                            <span className="section">
                                                Budgeted Limit
                                            </span>
                                        </Box>
                                    </Box>
                                    <Box
                                        gridColumn="span 12"
                                        display="grid"
                                        gridTemplateColumns="repeat(12, 1fr)"
                                        marginBottom={"9px"}
                                        gridColumnGap={"16px"}
                                    >
                                        <Box gridColumn="span 3">
                                            <span className="label">
                                                Per request
                                            </span>
                                        </Box>
                                        <Box gridColumn="span 3">
                                            <span
                                                className="label"
                                                style={{
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                }}
                                            >
                                                Max annually (total)
                                            </span>
                                        </Box>
                                        <Box gridColumn="span 3" />
                                        <Box gridColumn="span 3">
                                            <span className="label">
                                                Per request
                                            </span>
                                        </Box>
                                    </Box>
                                    <Box
                                        gridColumn="span 12"
                                        display="grid"
                                        gridTemplateColumns="repeat(12, 1fr)"
                                        marginBottom={"51px"}
                                        gridColumnGap={"16px"}
                                    >
                                        <Box gridColumn="span 3">
                                            <CustomFastField
                                                required
                                                variant="outlined"
                                                id="nonBudgeted"
                                                name="nonBudgeted"
                                                type="number"
                                                component={CurrencyField}
                                            />
                                        </Box>
                                        <Box gridColumn="span 3">
                                            <CustomFastField
                                                required
                                                variant="outlined"
                                                id="maxAnually"
                                                name="maxAnually"
                                                type="number"
                                                component={CurrencyField}
                                            />
                                        </Box>
                                        <Box
                                            gridColumn="span 3"
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <BorderLeftDiv />
                                        </Box>
                                        <Box gridColumn="span 3">
                                            <CustomFastField
                                                required
                                                variant="outlined"
                                                id="budgeted"
                                                name="budgeted"
                                                type="number"
                                                component={CurrencyField}
                                            />
                                        </Box>
                                        {showChangedBudgedWarning && (
                                            <Box
                                                gridColumn="span 12"
                                                style={{
                                                    display: "flex",
                                                }}
                                            >
                                                <OutlinedBox>
                                                    <StyledImage
                                                        src={InfoImage}
                                                    />
                                                    <span className="radio-button">
                                                        NOTE: Adjusting budget
                                                        limits will affect
                                                        budgets of previous
                                                        years!
                                                    </span>
                                                </OutlinedBox>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                                <Box gridColumn="span 12" marginBottom={"21px"}>
                                    <span className="section">
                                        Areas of approval
                                    </span>
                                </Box>
                                <Box gridColumn="span 12">
                                    <span className="label">Sites</span>
                                </Box>
                                <Box gridColumn="span 12" marginBottom={"25px"}>
                                    <FieldArray name="sites">
                                        {({ insert, remove, push }) => (
                                            <div className={classes.fieldArray}>
                                                <Select
                                                    id="sites-select"
                                                    classes={{
                                                        outlined:
                                                            selectClasses.outlined,
                                                    }}
                                                    defaultValue=""
                                                    value=""
                                                    displayEmpty={true}
                                                    renderValue={(value) =>
                                                        "Add +"
                                                    }
                                                    IconComponent={() => ""}
                                                    variant="outlined"
                                                    disabled={
                                                        filteredSiteOptions.length ===
                                                        0
                                                    }
                                                    onChange={(event) => {
                                                        push(
                                                            event.target.value
                                                        );
                                                    }}
                                                >
                                                    {filteredSiteOptions.map(
                                                        (aSite) => {
                                                            return (
                                                                <MenuItem
                                                                    value={
                                                                        aSite
                                                                    }
                                                                    key={
                                                                        aSite.id
                                                                    }
                                                                >
                                                                    {aSite.name}
                                                                </MenuItem>
                                                            );
                                                        }
                                                    )}
                                                </Select>
                                                <div className={classes.chips}>
                                                    {formik.values.sites
                                                        .length > 0 &&
                                                        formik.values.sites.map(
                                                            (site, index) => (
                                                                <StyledChip
                                                                    key={index}
                                                                    variant="outlined"
                                                                    label={
                                                                        site.name
                                                                    }
                                                                    onDelete={() => {
                                                                        remove(
                                                                            index
                                                                        );
                                                                    }}
                                                                />
                                                            )
                                                        )}
                                                </div>
                                            </div>
                                        )}
                                    </FieldArray>
                                    {formik.values.sites.length === 0 && (
                                        <Box
                                            gridColumn="span 12"
                                            style={{
                                                display: "flex",
                                            }}
                                        >
                                            <OutlinedBox>
                                                <StyledImage src={InfoImage} />
                                                <span className="radio-button">
                                                    NOTE: All sites selected
                                                </span>
                                            </OutlinedBox>
                                        </Box>
                                    )}
                                </Box>
                                <Box gridColumn="span 12">
                                    <span className="label">
                                        Type of investments
                                    </span>
                                </Box>
                                <Box gridColumn="span 12" marginBottom={"41px"}>
                                    <FieldArray
                                        name="investmentTypes"
                                        label="test"
                                    >
                                        {({ insert, remove, push }) => (
                                            <div className={classes.fieldArray}>
                                                <Select
                                                    id="investment-types-select"
                                                    classes={{
                                                        outlined:
                                                            selectClasses.outlined,
                                                    }}
                                                    defaultValue=""
                                                    value=""
                                                    displayEmpty={true}
                                                    renderValue={(value) =>
                                                        "Add +"
                                                    }
                                                    IconComponent={() => ""}
                                                    variant="outlined"
                                                    disabled={
                                                        filteredInvestmentTypeOptions.length ===
                                                        0
                                                    }
                                                    onChange={(event) => {
                                                        push(
                                                            event.target.value
                                                        );
                                                    }}
                                                >
                                                    {filteredInvestmentTypeOptions.map(
                                                        (aInvestmentType) => {
                                                            return (
                                                                <MenuItem
                                                                    value={
                                                                        aInvestmentType
                                                                    }
                                                                    key={
                                                                        aInvestmentType.id
                                                                    }
                                                                >
                                                                    {
                                                                        aInvestmentType.name
                                                                    }
                                                                </MenuItem>
                                                            );
                                                        }
                                                    )}
                                                </Select>
                                                <div className={classes.chips}>
                                                    {formik.values
                                                        .investmentTypes
                                                        .length > 0 &&
                                                        formik.values.investmentTypes.map(
                                                            (
                                                                investmentType,
                                                                index
                                                            ) => (
                                                                <StyledChip
                                                                    key={index}
                                                                    variant="outlined"
                                                                    label={
                                                                        investmentType.name
                                                                    }
                                                                    onDelete={() => {
                                                                        remove(
                                                                            index
                                                                        );
                                                                    }}
                                                                />
                                                            )
                                                        )}
                                                </div>
                                            </div>
                                        )}
                                    </FieldArray>
                                    {formik.values.investmentTypes.length ===
                                        0 && (
                                        <Box
                                            gridColumn="span 12"
                                            style={{
                                                display: "flex",
                                            }}
                                        >
                                            <OutlinedBox>
                                                <StyledImage src={InfoImage} />
                                                <span className="radio-button">
                                                    NOTE: All types of
                                                    investment selected
                                                </span>
                                            </OutlinedBox>
                                        </Box>
                                    )}
                                </Box>
                                <Box gridColumn="span 12">
                                    {isEditing ? (
                                        <ButtonGroup
                                            color="primary"
                                            fullWidth
                                            disableElevation
                                            disableFocusRipple
                                            disableRipple
                                        >
                                            <SubmitButton
                                                type="submit"
                                                variant="contained"
                                                size="large"
                                                disabled={props.isSubmitting}
                                                style={
                                                    !props.isSubmitting
                                                        ? {
                                                              backgroundColor:
                                                                  "#393939",
                                                              color: "#FFF",
                                                          }
                                                        : {
                                                              backgroundColor:
                                                                  "#777",
                                                              color: "#FFF",
                                                          }
                                                }
                                            >
                                                <span className="cta">
                                                    Save changes
                                                </span>
                                            </SubmitButton>
                                            {enableDelete ? (
                                                <Button
                                                    variant="contained"
                                                    style={{
                                                        backgroundColor:
                                                            "#C72D2D",
                                                    }}
                                                    onClick={() => {
                                                        handleDeleteConfirm(
                                                            formik.values.id
                                                        );
                                                    }}
                                                >
                                                    <span className="bold-link-confirm">
                                                        Confirm delete
                                                    </span>
                                                    <img src={DeleteImage} />
                                                </Button>
                                            ) : (
                                                <Button
                                                    variant="text"
                                                    onClick={() => {
                                                        handleDelete(
                                                            formik.values.id
                                                        );
                                                    }}
                                                >
                                                    <span className="bold-link">
                                                        Delete approver
                                                    </span>
                                                    <img src={DeleteImage} />
                                                </Button>
                                            )}
                                        </ButtonGroup>
                                    ) : (
                                        <ButtonGroup
                                            color="primary"
                                            fullWidth
                                            disableElevation
                                            disableFocusRipple
                                            disableRipple
                                        >
                                            <SubmitButton
                                                fullWidth
                                                disableElevation
                                                disableFocusRipple
                                                disableRipple
                                                type="submit"
                                                variant="contained"
                                                size="large"
                                                disabled={props.isSubmitting}
                                                style={
                                                    !props.isSubmitting
                                                        ? {
                                                              backgroundColor:
                                                                  "#393939",
                                                              color: "#FFF",
                                                          }
                                                        : {
                                                              backgroundColor:
                                                                  "#777",
                                                              color: "#FFF",
                                                          }
                                                }
                                            >
                                                <span className="cta">
                                                    Create approver
                                                </span>
                                            </SubmitButton>
                                        </ButtonGroup>
                                    )}
                                </Box>
                            </FormControl>
                        </Form>
                    );
                }}
            </Formik>
        </MuiPickersUtilsProvider>
    );
};

export default ApproverForm;
