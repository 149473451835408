import { protectedResources } from "./authConfig";
import { msalInstance } from "./index";

const getToken = async (scopes) => {
    const account = msalInstance.getActiveAccount();

    if (!account) {
        throw Error(
            "No active account! Verify a user has been signed in and setActiveAccount has been called."
        );
    }

    const request = {
        account: account,
        scopes: scopes,
    };

    let response;

    try {
        response = await msalInstance.acquireTokenSilent(request);
    } catch (error) {
        console.error(error);
        if (error.errorCode === "no_tokens_found") {
            msalInstance.loginRedirect({
                scopes: scopes,
            });
        }

        if (
            error.errorCode === "consent_required" ||
            error.errorCode === "interaction_required" ||
            error.errorCode === "login_required"
        ) {
            response = await msalInstance.acquireTokenRedirect(request);
        }
    }

    if (response) return response.accessToken;
};

export const getGroups = async () => {
    const accessToken = await getToken(protectedResources.apiGraph.scopes);
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers,
    };

    const response = await fetch(protectedResources.apiGraph.endpoint, options);

    if (response.status === 200) {
        const json = await response
            .json()
            .catch((error) => console.error(error));

        return json;
    }

    return response;
};

export const getNextPage = async (nextPage) => {
    const accessToken = await getToken(protectedResources.apiGraph.scopes);

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers,
    };

    const response = await fetch(url, options);

    if (response.status === 200) {
        const json = await response
            .json()
            .catch((error) => console.error(error));

        return json;
    }

    return response;
};

export const capexApiGet = async (url) => {
    const accessToken = await getToken(protectedResources.apiCapex.scopes);

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers,
    };

    const response = await fetch(url, options);
    const json = await response.json().catch((error) => console.error(error));

    if (json) json.success = response.status === 200;

    return json;
};

export const capexApiPost = async (url, body) => {
    const accessToken = await getToken(protectedResources.apiCapex.scopes);

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");

    const options = {
        method: "POST",
        headers: headers,
        body,
    };

    const response = await fetch(url, options);
    const json = await response.json();

    if (json) json.success = response.status === 200;

    return json;
};

export const capexApiPut = async (url, body) => {
    const accessToken = await getToken(protectedResources.apiCapex.scopes);

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "PUT",
        headers: headers,
        body,
    };

    const response = await fetch(url, options);
    const json = await response.json().catch((error) => console.error(error));

    if (json) json.success = response.status === 200;

    return json;
};

export const capexApiDelete = async (url, body) => {
    const accessToken = await getToken(protectedResources.apiCapex.scopes);

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "DELETE",
        headers: headers,
        body,
    };

    const response = await fetch(url, options);
    const json = await response.json().catch((error) => console.error(error));

    if (json) json.success = response.status === 200;

    return json;
};

export const downloadFile = async (fileName) => {
    const accessToken = await getToken(protectedResources.apiCapex.scopes);
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    const downloadUrl =
        protectedResources.apiCapex.endpoint + "/file/" + fileName;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers,
    };

    const response = await fetch(downloadUrl, options);

    if (response.status === 200) {
        var blob = await response.blob().catch((error) => console.error(error));

        downloadBlob(blob, fileName);
    }

    return response;
};

const downloadBlob = (blob, fileName) => {
    var url = window.URL.createObjectURL(blob);
    var a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
};

export const downloadDataDump = async () => {
    const accessToken = await getToken(protectedResources.apiCapex.scopes);
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    const downloadUrl = protectedResources.apiCapex.endpoint + "/report/export";

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers,
    };

    const response = await fetch(downloadUrl, options);

    if (response.status === 200) {
        var blob = await response.blob().catch((error) => console.error(error));

        downloadBlob(blob, "CapexDataExport.xlsx");
    }

    return response;
};

export const downloadSiteReport = async (url) => {
    const accessToken = await getToken(protectedResources.apiCapex.scopes);
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers,
    };

    const response = await fetch(url, options);

    if (response.status === 200) {
        var blob = await response.blob().catch((error) => console.error(error));

        downloadBlob(blob, "CapexSiteReport.xlsx");
    }

    return response;
};

export const downloadSummaryReport = async (url) => {
    const accessToken = await getToken(protectedResources.apiCapex.scopes);
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers,
    };

    const response = await fetch(url, options);

    if (response.status === 200) {
        var blob = await response.blob().catch((error) => console.error(error));

        downloadBlob(blob, "CapexSummaryReport.xlsx");
    }

    return response;
};
