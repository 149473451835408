import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import styled from "styled-components";
import DataTemplate from "./../../components/DataTemplate.jsx";
import AdminTemplate from "./../admin/template";
import { protectedResources } from "./../../authConfig";
import ExportLink from "./../../components/ExportLink";
import { capexApiGet, downloadSiteReport } from "../../fetch";
import { StyledMUITextField, SubmitButton } from "./../../components/Form";
import RemainingBudget from "../../components/RemainingBudget.jsx";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    ButtonGroup,
} from "@material-ui/core";
import { ColumnContainer } from "../../components/Layout.jsx";

const styles = (theme) => ({
    table: {
        minWidth: 650,
    },
    firstCell: {
        minWidth: "250px",
    },
});

const Section = styled.div`
    border: 1px solid;
    border-color: #a4aeb7;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 20px;
`;

const Site = (props) => {
    const { classes } = props;
    const [data, setData] = React.useState();
    const [options, setOptions] = React.useState();
    const [isFetching, setIsFetching] = React.useState(false);
    const [isExporting, setIsExporting] = React.useState(false);
    const siteUrl = protectedResources.apiCapex.endpoint + "/report/site";
    const exportUrl =
        protectedResources.apiCapex.endpoint + "/report/exportSite";
    const siteOptionsUrl =
        protectedResources.apiCapex.endpoint + "/report/siteOptions";

    const [budgetedOptions, setBudgetedOptions] = React.useState([]);
    const [budgetedOption, setBudgetedOption] = React.useState(0);
    const currentYear = new Date().getFullYear();
    const [siteOptions, setSiteOptions] = React.useState([]);
    const [site, setSite] = React.useState(null);
    const [fiscalYearOptions, setFiscalYearOptions] = React.useState([]);
    const [fiscalYear, setFiscalYear] = React.useState(currentYear);

    const onHandleSubmit = async () => {
        setData(null);
        setIsFetching(true);

        const response = await capexApiGet(
            siteUrl + "/" + site.id + "/" + fiscalYear + "/" + budgetedOption
        );

        setData(response);
        setIsFetching(false);
    };

    const exportSiteReport = async () => {
        setIsExporting(true);

        const url =
            exportUrl + "/" + site.id + "/" + fiscalYear + "/" + budgetedOption;

        await downloadSiteReport(url);

        setIsExporting(false);
    };

    let budgetedOptionText = "";

    switch (budgetedOption) {
        case 0:
            budgetedOptionText = "Budgeted & Non-budgeted";
            break;
        case 1:
            budgetedOptionText = "Budgeted";
            break;
        case 2:
            budgetedOptionText = "Non-budgeted";
            break;
    }

    return (
        <AdminTemplate>
            <DataTemplate
                {...props}
                url={siteOptionsUrl}
                data={options}
                setData={(result) => {
                    setSiteOptions(result.siteOptions);
                    setSite(result.siteOptions[0]);
                    setFiscalYearOptions(result.fiscalYearOptions);
                    setBudgetedOptions(result.budgetedOptions);
                    setOptions(result);
                }}
            >
                <ColumnContainer>
                    <Box gridColumn="span 12" marginBottom={"5px"}>
                        <span className="headline-15-caps">Admin</span>
                    </Box>
                    <Box marginBottom={"10px"}>
                        <span className="headline-l">Report - site</span>
                    </Box>
                    <Box marginBottom={"34px"}>
                        <ExportLink
                            disabled={isExporting}
                            onClick={() => {
                                exportSiteReport();
                            }}
                        />
                    </Box>
                    <Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        marginBottom={"40px"}
                    >
                        <Box gridColumn="span 9">
                            <Section>
                                <Box
                                    display="grid"
                                    gridTemplateColumns="repeat(12, 1fr)"
                                    gridColumnGap={10}
                                >
                                    <Box
                                        gridColumn="span 12"
                                        marginBottom={"13px"}
                                    >
                                        <span className="headline-s">
                                            Report settings
                                        </span>
                                    </Box>
                                    <Box
                                        gridColumn="span 12"
                                        display="grid"
                                        gridTemplateColumns="repeat(12, 1fr)"
                                        gridColumnGap={10}
                                        marginBottom={"3px"}
                                    >
                                        <Box gridColumn="span 3">
                                            <span className="label">Site</span>
                                        </Box>
                                        <Box gridColumn="span 3">
                                            <span className="label">
                                                Fiscal year
                                            </span>
                                        </Box>
                                        <Box gridColumn="span 3">
                                            <span className="label">
                                                Budgeted requests
                                            </span>
                                        </Box>
                                        <Box gridColumn="span 3" />
                                    </Box>
                                    <Box gridColumn="span 3">
                                        <Autocomplete
                                            id="site"
                                            name="site"
                                            openOnFocus
                                            disableClearable
                                            value={site}
                                            options={siteOptions}
                                            getOptionLabel={(option) =>
                                                option.name
                                            }
                                            onChange={(e, option) => {
                                                setSite(option);
                                            }}
                                            renderInput={(params) => (
                                                <StyledMUITextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    name="site"
                                                    {...params}
                                                />
                                            )}
                                        />
                                    </Box>
                                    <Box gridColumn="span 3">
                                        <Autocomplete
                                            id="fiscalYear"
                                            name="fiscalYear"
                                            disableClearable
                                            options={fiscalYearOptions}
                                            value={fiscalYear}
                                            getOptionLabel={(option) =>
                                                option === -1
                                                    ? "All"
                                                    : option.toString()
                                            }
                                            onChange={(e, option) => {
                                                setFiscalYear(option);
                                            }}
                                            renderInput={(params) => (
                                                <StyledMUITextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    name="fiscalYear"
                                                    {...params}
                                                />
                                            )}
                                        />
                                    </Box>
                                    <Box gridColumn="span 3">
                                        <Autocomplete
                                            id="budgetedRequests"
                                            name="budgetedRequests"
                                            disableClearable
                                            options={budgetedOptions}
                                            value={budgetedOption}
                                            getOptionLabel={(option) => {
                                                switch (option) {
                                                    case 0:
                                                        return "All";
                                                    case 1:
                                                        return "Budgeted";
                                                    case 2:
                                                        return "Non-budgeted";
                                                }
                                            }}
                                            onChange={(e, option) => {
                                                setBudgetedOption(option);
                                            }}
                                            renderInput={(params) => (
                                                <StyledMUITextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    name="budgeted"
                                                    {...params}
                                                />
                                            )}
                                        />
                                    </Box>
                                    <Box gridColumn="span 3">
                                        <ButtonGroup
                                            color="primary"
                                            fullWidth
                                            disableElevation
                                            disableFocusRipple
                                            disableRipple
                                            style={{ paddingLeft: "42px" }}
                                        >
                                            <SubmitButton
                                                variant="contained"
                                                size="large"
                                                disabled={isFetching}
                                                onClick={() => {
                                                    onHandleSubmit();
                                                }}
                                                style={{
                                                    backgroundColor: "#393939",
                                                    color: "#FFF",
                                                }}
                                            >
                                                {isFetching ? (
                                                    <CircularProgress />
                                                ) : (
                                                    <span className="cta">
                                                        Generate report
                                                    </span>
                                                )}
                                            </SubmitButton>
                                        </ButtonGroup>
                                    </Box>
                                </Box>
                            </Section>
                        </Box>
                    </Box>
                    {data && (
                        <React.Fragment>
                            <Box
                                display="grid"
                                gridTemplateColumns="repeat(12, 1fr)"
                                gridColumnGap={10}
                                marginBottom={"12px"}
                            >
                                <Box gridColumn="span 12">
                                    <span className="headline-s">
                                        {site.name} |
                                        {fiscalYear === -1
                                            ? "All fiscal years"
                                            : fiscalYear}{" "}
                                        | {budgetedOptionText}
                                    </span>
                                </Box>
                            </Box>
                            <Box gridColumn="span 12" marginBottom={"46px"}>
                                <TableContainer>
                                    <Table
                                        sx={{ minWidth: 650 }}
                                        aria-label="Requests"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell
                                                    className={
                                                        classes.firstCell
                                                    }
                                                >
                                                    <span className="label">
                                                        Site
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span className="label">
                                                        Requested amount
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span className="label">
                                                        (# of requests)
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span className="label">
                                                        Pending
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span className="label">
                                                        Declined
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span className="label">
                                                        Approved
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span className="label">
                                                        Remaining non-budgeted
                                                    </span>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                <TableRow
                                                    key={data.total.name}
                                                    style={{
                                                        background: "white",
                                                    }}
                                                    sx={{
                                                        "&:last-child td, &:last-child th":
                                                            {
                                                                border: 0,
                                                            },
                                                    }}
                                                >
                                                    <TableCell
                                                        className={
                                                            classes.firstCell
                                                        }
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        <span className="input">
                                                            {data.total.name}
                                                        </span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span className="input">
                                                            {
                                                                data.total
                                                                    .totalAmount
                                                            }{" "}
                                                        </span>
                                                        <span className="unit">
                                                            kEUR
                                                        </span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span className="unit-blue">
                                                            (
                                                            {
                                                                data.total
                                                                    .requestCount
                                                            }
                                                            )
                                                        </span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span className="input">
                                                            {
                                                                data.total
                                                                    .pendingAmount
                                                            }{" "}
                                                        </span>
                                                        <span className="unit">
                                                            kEUR
                                                        </span>
                                                        <span className="unit-blue">
                                                            (
                                                            {
                                                                data.total
                                                                    .pendingCount
                                                            }
                                                            )
                                                        </span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span className="input">
                                                            {
                                                                data.total
                                                                    .declinedAmount
                                                            }{" "}
                                                        </span>
                                                        <span className="unit">
                                                            kEUR
                                                        </span>
                                                        <span className="unit-blue">
                                                            (
                                                            {
                                                                data.total
                                                                    .declinedAmount
                                                            }
                                                            )
                                                        </span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span className="input">
                                                            {
                                                                data.total
                                                                    .approvedAmount
                                                            }{" "}
                                                        </span>
                                                        <span className="unit">
                                                            kEUR
                                                        </span>
                                                        <span className="unit-blue">
                                                            (
                                                            {
                                                                data.total
                                                                    .approvedCount
                                                            }
                                                            )
                                                        </span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <RemainingBudget
                                                            remainingBudget={
                                                                data.total
                                                                    .remainingNonBudgeted
                                                            }
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                            <Box
                                display="grid"
                                gridTemplateColumns="repeat(12, 1fr)"
                                gridColumnGap={10}
                                marginBottom={"18px"}
                            >
                                <Box gridColumn="span 12">
                                    <span className="unit-spacing">
                                        By division
                                    </span>
                                </Box>
                            </Box>
                            <Box gridColumn="span 12" marginBottom={"46px"}>
                                <TableContainer>
                                    <Table
                                        className={classes.table}
                                        aria-label="Requests"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell
                                                    className={
                                                        classes.firstCell
                                                    }
                                                >
                                                    <span className="label">
                                                        Division
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span className="label">
                                                        Requested amount
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span className="label">
                                                        (# of requests)
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span className="label">
                                                        Pending
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span className="label">
                                                        Declined
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span className="label">
                                                        Approved
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span className="label">
                                                        Remaining non-budgeted
                                                    </span>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.divisionRequests.map(
                                                (
                                                    {
                                                        name,
                                                        approvedAmount,
                                                        approvedCount,
                                                        declinedAmount,
                                                        declinedCount,
                                                        pendingAmount,
                                                        pendingCount,
                                                        remainingNonBudgeted,
                                                        requestCount,
                                                        totalAmount,
                                                    },
                                                    index
                                                ) => (
                                                    <TableRow
                                                        key={name}
                                                        style={
                                                            index % 2
                                                                ? {
                                                                      background:
                                                                          "none",
                                                                  }
                                                                : {
                                                                      background:
                                                                          "white",
                                                                  }
                                                        }
                                                        sx={{
                                                            "&:last-child td, &:last-child th":
                                                                {
                                                                    border: 0,
                                                                },
                                                        }}
                                                    >
                                                        <TableCell
                                                            className={
                                                                classes.firstCell
                                                            }
                                                        >
                                                            <span className="input">
                                                                {name}
                                                            </span>
                                                        </TableCell>
                                                        <TableCell>
                                                            <span className="input">
                                                                {totalAmount}{" "}
                                                            </span>
                                                            <span className="unit">
                                                                kEUR
                                                            </span>
                                                        </TableCell>
                                                        <TableCell>
                                                            <span className="unit-blue">
                                                                ({requestCount})
                                                            </span>
                                                        </TableCell>
                                                        <TableCell>
                                                            <span className="input">
                                                                {pendingAmount}{" "}
                                                            </span>
                                                            <span className="unit">
                                                                kEUR
                                                            </span>
                                                            <span className="unit-blue">
                                                                ({pendingCount})
                                                            </span>
                                                        </TableCell>
                                                        <TableCell>
                                                            <span className="input">
                                                                {declinedAmount}{" "}
                                                            </span>
                                                            <span className="unit">
                                                                kEUR
                                                            </span>
                                                            <span className="unit-blue">
                                                                ({declinedCount}
                                                                )
                                                            </span>
                                                        </TableCell>
                                                        <TableCell>
                                                            <span className="input">
                                                                {approvedAmount}{" "}
                                                            </span>
                                                            <span className="unit">
                                                                kEUR
                                                            </span>
                                                            <span className="unit-blue">
                                                                ({approvedCount}
                                                                )
                                                            </span>
                                                        </TableCell>
                                                        <TableCell>
                                                            <span className="input">
                                                                -
                                                            </span>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                            <Box
                                display="grid"
                                gridTemplateColumns="repeat(12, 1fr)"
                                gridColumnGap={10}
                                marginBottom={"18px"}
                            >
                                <Box gridColumn="span 12">
                                    <span className="unit-spacing">
                                        By type of investment
                                    </span>
                                </Box>
                            </Box>
                            <Box gridColumn="span 12" marginBottom={"46px"}>
                                <TableContainer>
                                    <Table
                                        sx={{ minWidth: 650 }}
                                        aria-label="Requests"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell
                                                    className={
                                                        classes.firstCell
                                                    }
                                                >
                                                    <span className="label">
                                                        Type of investment
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span className="label">
                                                        Requested amount
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span className="label">
                                                        (# of requests)
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span className="label">
                                                        Pending
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span className="label">
                                                        Declined
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span className="label">
                                                        Approved
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span className="label">
                                                        Remaining non-budgeted
                                                    </span>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.investmentTypeRequests.map(
                                                (
                                                    {
                                                        name,
                                                        approvedAmount,
                                                        approvedCount,
                                                        declinedAmount,
                                                        declinedCount,
                                                        pendingAmount,
                                                        pendingCount,
                                                        remainingNonBudgeted,
                                                        requestCount,
                                                        totalAmount,
                                                    },
                                                    index
                                                ) => (
                                                    <TableRow
                                                        key={name}
                                                        style={
                                                            index % 2
                                                                ? {
                                                                      background:
                                                                          "none",
                                                                  }
                                                                : {
                                                                      background:
                                                                          "white",
                                                                  }
                                                        }
                                                        sx={{
                                                            "&:last-child td, &:last-child th":
                                                                {
                                                                    border: 0,
                                                                },
                                                        }}
                                                    >
                                                        <TableCell
                                                            className={
                                                                classes.firstCell
                                                            }
                                                        >
                                                            <span className="input">
                                                                {name}
                                                            </span>
                                                        </TableCell>
                                                        <TableCell>
                                                            <span className="input">
                                                                {totalAmount}{" "}
                                                            </span>
                                                            <span className="unit">
                                                                kEUR
                                                            </span>
                                                        </TableCell>
                                                        <TableCell>
                                                            <span className="unit-blue">
                                                                ({requestCount})
                                                            </span>
                                                        </TableCell>
                                                        <TableCell>
                                                            <span className="input">
                                                                {pendingAmount}{" "}
                                                            </span>
                                                            <span className="unit">
                                                                kEUR
                                                            </span>
                                                            <span className="unit-blue">
                                                                ({pendingCount})
                                                            </span>
                                                        </TableCell>
                                                        <TableCell>
                                                            <span className="input">
                                                                {declinedAmount}{" "}
                                                            </span>
                                                            <span className="unit">
                                                                kEUR
                                                            </span>
                                                            <span className="unit-blue">
                                                                ({declinedCount}
                                                                )
                                                            </span>
                                                        </TableCell>
                                                        <TableCell>
                                                            <span className="input">
                                                                {approvedAmount}{" "}
                                                            </span>
                                                            <span className="unit">
                                                                kEUR
                                                            </span>
                                                            <span className="unit-blue">
                                                                ({approvedCount}
                                                                )
                                                            </span>
                                                        </TableCell>
                                                        <TableCell>
                                                            <RemainingBudget
                                                                remainingBudget={
                                                                    remainingNonBudgeted
                                                                }
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                            <Box
                                display="grid"
                                gridTemplateColumns="repeat(12, 1fr)"
                                gridColumnGap={10}
                                marginBottom={"18px"}
                            >
                                <Box gridColumn="span 12">
                                    <span className="unit-spacing">
                                        By approver
                                    </span>
                                </Box>
                            </Box>
                            <Box gridColumn="span 12" marginBottom={"46px"}>
                                <TableContainer>
                                    <Table
                                        sx={{ minWidth: 650 }}
                                        aria-label="Requests"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell
                                                    className={
                                                        classes.firstCell
                                                    }
                                                >
                                                    <span className="label">
                                                        Approver
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span className="label">
                                                        Requested amount
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span className="label">
                                                        (# of requests)
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span className="label">
                                                        Pending
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span className="label">
                                                        Declined
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span className="label">
                                                        Approved
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span className="label">
                                                        Remaining non-budgeted
                                                    </span>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.approverRequests.map(
                                                (
                                                    {
                                                        name,
                                                        approvedAmount,
                                                        approvedCount,
                                                        declinedAmount,
                                                        declinedCount,
                                                        pendingAmount,
                                                        pendingCount,
                                                        remainingNonBudgeted,
                                                        requestCount,
                                                        totalAmount,
                                                    },
                                                    index
                                                ) => (
                                                    <TableRow
                                                        key={name}
                                                        style={
                                                            index % 2
                                                                ? {
                                                                      background:
                                                                          "none",
                                                                  }
                                                                : {
                                                                      background:
                                                                          "white",
                                                                  }
                                                        }
                                                        sx={{
                                                            "&:last-child td, &:last-child th":
                                                                {
                                                                    border: 0,
                                                                },
                                                        }}
                                                    >
                                                        <TableCell
                                                            className={
                                                                classes.firstCell
                                                            }
                                                        >
                                                            <span className="input">
                                                                {name}
                                                            </span>
                                                        </TableCell>
                                                        <TableCell>
                                                            <span className="input">
                                                                {totalAmount}{" "}
                                                            </span>
                                                            <span className="unit">
                                                                kEUR
                                                            </span>
                                                        </TableCell>
                                                        <TableCell>
                                                            <span className="unit-blue">
                                                                ({requestCount})
                                                            </span>
                                                        </TableCell>
                                                        <TableCell>
                                                            <span className="input">
                                                                {pendingAmount}{" "}
                                                            </span>
                                                            <span className="unit">
                                                                kEUR
                                                            </span>
                                                            <span className="unit-blue">
                                                                ({pendingCount})
                                                            </span>
                                                        </TableCell>
                                                        <TableCell>
                                                            <span className="input">
                                                                {declinedAmount}{" "}
                                                            </span>
                                                            <span className="unit">
                                                                kEUR
                                                            </span>
                                                            <span className="unit-blue">
                                                                ({declinedCount}
                                                                )
                                                            </span>
                                                        </TableCell>
                                                        <TableCell>
                                                            <span className="input">
                                                                {approvedAmount}{" "}
                                                            </span>
                                                            <span className="unit">
                                                                kEUR
                                                            </span>
                                                            <span className="unit-blue">
                                                                ({approvedCount}
                                                                )
                                                            </span>
                                                        </TableCell>
                                                        <TableCell>
                                                            <RemainingBudget
                                                                remainingBudget={
                                                                    remainingNonBudgeted
                                                                }
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </React.Fragment>
                    )}
                </ColumnContainer>
            </DataTemplate>
        </AdminTemplate>
    );
};

export default withStyles(styles)(Site);
