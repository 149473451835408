import React from "react";
import { Route, Switch, useLocation, useHistory } from "react-router-dom";
import { useEffect } from "react";
import { Overage } from "./pages/Overage";
import { RouteGuard } from "./components/RouteGuard";
import RequestIndex from "./pages/request/index.jsx";
import RequestCreate from "./pages/request/create.jsx";
import RequestReview from "./pages/request/review.jsx";
import RequestDetails from "./pages/request/details.jsx";
import RequestEdit from "./pages/request/edit.jsx";
import RequestConfirm from "./pages/request/confirm.jsx";
import ApproverIndex from "./pages/approver/index.jsx";
import ApproverRequestError from "./pages/approver/requestError.jsx";
import ApproverRequests from "./pages/approver/requests.jsx";
import ApproverCreate from "./pages/approver/create.jsx";
import ApproverEdit from "./pages/approver/edit.jsx";
import AdminIndex from "./pages/admin/index.jsx";
import AdminDivisions from "./pages/admin/divisions.jsx";
import AdminFinanceTypes from "./pages/admin/financeTypes.jsx";
import AdminInvestmentTypes from "./pages/admin/investmentTypes.jsx";
import AdminSites from "./pages/admin/sites.jsx";
import AdminTexts from "./pages/admin/texts.jsx";
import TextDetails from "./pages/admin/text.jsx";
import AdminExport from "./pages/admin/export.jsx";
import ReportCapexSummary from "./pages/report/capexSummary.jsx";
import ReportRequests from "./pages/report/requests.jsx";
import ReportSite from "./pages/report/site.jsx";
import FourHundred from "./pages/error/400.jsx";
import FourOhOne from "./pages/error/401.jsx";
import FourOhThree from "./pages/error/403.jsx";
import FourOhFour from "./pages/error/404.jsx";
import FiveHundred from "./pages/error/500.jsx";
import Index from "./pages/index.jsx";
import { capexApiGet } from "./fetch";
import { protectedResources } from "./authConfig";

const Routes = ({ stateUrl }) => {
    const [securityGroups, setSecurityGroups] = React.useState();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const history = useHistory();
    const url = protectedResources.apiCapex.endpoint + "/groups/";

    const getgGoups = async () => {
        const response = await capexApiGet(url);
        setSecurityGroups(response);
    };

    React.useEffect(() => {}, []);

    useEffect(() => {
        if (stateUrl) {
            history.replace(stateUrl);
        }

        getgGoups();
    }, []);

    if (!securityGroups) {
        return <Switch />;
    }

    return (
        <Switch>
            <Route exact path="/" component={Index} />
            <RouteGuard
                exact
                path="/admin"
                groups={[securityGroups.groupAdmin]}
                Component={AdminIndex}
            />
            <RouteGuard
                exact
                path="/admin/divisions"
                groups={[securityGroups.groupAdmin]}
                Component={AdminDivisions}
            />
            <RouteGuard
                exact
                path="/admin/financeTypes"
                groups={[securityGroups.groupAdmin]}
                Component={AdminFinanceTypes}
            />
            <RouteGuard
                exact
                path="/admin/investmentTypes"
                groups={[securityGroups.groupAdmin]}
                Component={AdminInvestmentTypes}
            />
            <RouteGuard
                exact
                path="/admin/sites"
                groups={[securityGroups.groupAdmin]}
                Component={AdminSites}
            />
            <RouteGuard
                exact
                path="/texts"
                groups={[securityGroups.groupAdmin]}
                Component={AdminTexts}
            />
            <RouteGuard
                exact
                path="/text/:id"
                groups={[securityGroups.groupAdmin]}
                Component={TextDetails}
            />
            <RouteGuard
                exact
                path="/admin/export"
                groups={[securityGroups.groupAdmin]}
                Component={AdminExport}
            />
            <RouteGuard
                exact
                path="/report/capex"
                groups={[securityGroups.groupAdmin]}
                Component={ReportCapexSummary}
            />
            <RouteGuard
                exact
                path="/report/requests"
                groups={[securityGroups.groupAdmin]}
                Component={ReportRequests}
            />
            <RouteGuard
                exact
                path="/report/site"
                groups={[securityGroups.groupAdmin]}
                Component={ReportSite}
            />
            <RouteGuard
                exact
                path="/request"
                groups={[
                    securityGroups.groupRequester,
                    securityGroups.groupAdmin,
                ]}
                Component={RequestIndex}
            />
            <RouteGuard
                exact
                path="/request/create"
                groups={[
                    securityGroups.groupRequester,
                    securityGroups.groupAdmin,
                ]}
                Component={RequestCreate}
            />
            <RouteGuard
                exact
                path="/request/:id"
                groups={[
                    securityGroups.groupRequester,
                    securityGroups.groupApprover,
                    securityGroups.groupAdmin,
                ]}
            >
                <RequestDetails
                    template={query.get("template")}
                ></RequestDetails>
            </RouteGuard>
            <RouteGuard
                exact
                path="/request/edit/:id"
                groups={[
                    securityGroups.groupRequester,
                    securityGroups.groupAdmin,
                ]}
                Component={RequestEdit}
            />
            <RouteGuard
                exact
                path="/request/confirm/:id"
                groups={[
                    securityGroups.groupRequester,
                    securityGroups.groupAdmin,
                ]}
                Component={RequestConfirm}
            />
            <RouteGuard
                exact
                path="/request/review/:id"
                groups={[
                    securityGroups.groupApprover,
                    securityGroups.groupAdmin,
                ]}
            >
                <RequestReview actionId={query.get("actionId")}></RequestReview>
            </RouteGuard>
            <RouteGuard
                exact
                path="/approver/requests"
                groups={[
                    securityGroups.groupApprover,
                    securityGroups.groupAdmin,
                ]}
                Component={ApproverRequests}
            />
            <RouteGuard
                exact
                path="/approver/create"
                groups={[securityGroups.groupAdmin]}
                Component={ApproverCreate}
            />
            <RouteGuard
                exact
                path="/approver/:id"
                groups={[securityGroups.groupApprover]}
                Component={ApproverEdit}
            />
            <RouteGuard
                exact
                path="/approver"
                groups={[securityGroups.groupApprover]}
                Component={ApproverIndex}
            />
            <RouteGuard
                exact
                path="/approver/requesterror"
                groups={[
                    securityGroups.groupApprover,
                    securityGroups.groupAdmin,
                ]}
                Component={ApproverRequestError}
            />
            <Route path="/overage">
                <Overage />
            </Route>
            <Route path="/400">
                <FourHundred />
            </Route>
            <Route path="/401">
                <FourOhOne />
            </Route>
            <Route path="/403">
                <FourOhThree />
            </Route>
            <Route path="/404">
                <FourOhFour />
            </Route>
            <Route path="/500">
                <FiveHundred />
            </Route>
            <Route path="*">
                <FourOhFour />
            </Route>
        </Switch>
    );
};

export default Routes;
