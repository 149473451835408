import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Box, TextField, ButtonGroup, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { protectedResources } from "./../../authConfig";
import AdminTemplate from "./template";
import { capexApiPost } from "../../fetch";
import { SubmitButton } from "./../../components/Form";
import DataTemplate from "./../../components/DataTemplate.jsx";
import { Container, ColumnLeft, Column } from "./../../components/Layout";

const styles = (theme) => ({
    outlinedInput: {
        border: "1px solid",
        borderColor: "#A4AEB7",
        borderRadius: 0,
        backgroundColor: "#FFF !important",
        padding: "18px 26px 28px 26px",
    },
});

const TextDetails = (props) => {
    const { classes } = props;
    const [data, setData] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [textInput, setTextInput] = React.useState(data && data.value);
    const location = useLocation();
    const history = useHistory();

    const handleSubmit = async () => {
        setIsSubmitting(true);

        const updatedText = { ...data };

        updatedText.value = textInput;

        const json = JSON.stringify(updatedText);

        await capexApiPost(
            protectedResources.apiCapex.endpoint + "/text/edit/",
            json
        );

        setIsSubmitting(false);
    };

    return (
        <AdminTemplate {...props}>
            <DataTemplate
                {...props}
                url={protectedResources.apiCapex.endpoint + location.pathname}
                data={data}
                setData={(aData) => {
                    setData(aData);
                    setTextInput(aData.value);
                }}
            >
                <Container>
                    <ColumnLeft>
                        {data && (
                            <Box
                                display="grid"
                                gridTemplateColumns="repeat(12, 1fr)"
                                gridColumnGap={10}
                                paddingBottom={"41px"}
                            >
                                <Box gridColumn="span 12">
                                    <span className="headline-15-caps">
                                        Admin
                                    </span>
                                </Box>
                                <Box gridColumn="span 12" marginBottom={"47px"}>
                                    <span className="headline-l">
                                        Manage text
                                    </span>
                                </Box>
                                <Box gridColumn="span 12" marginBottom={"8px"}>
                                    <span className="label">Editing</span>
                                </Box>
                                <Box gridColumn="span 12" marginBottom={"45px"}>
                                    <span className="text-header">
                                        {data.key}
                                    </span>
                                </Box>
                                <Box gridColumn="span 12" marginBottom={"36px"}>
                                    <span className="body-copy">
                                        {data.description}
                                    </span>
                                </Box>
                                <Box gridColumn="span 12" marginBottom={"8px"}>
                                    <span className="label">Text</span>
                                </Box>
                                <Box gridColumn="span 12" marginBottom={"47px"}>
                                    <TextField
                                        id="text"
                                        variant="filled"
                                        multiline
                                        fullWidth
                                        minRows={4}
                                        maxRows={4}
                                        className="textValueTextField"
                                        value={textInput}
                                        onChange={(event) => {
                                            setTextInput(
                                                event.currentTarget.value
                                            );
                                        }}
                                        InputProps={{
                                            classes: {
                                                root: classes.outlinedInput,
                                            },
                                        }}
                                    />
                                </Box>
                                <Box gridColumn="span 12" marginBottom={"30px"}>
                                    <ButtonGroup
                                        color="primary"
                                        fullWidth
                                        disableElevation
                                        disableFocusRipple
                                        disableRipple
                                    >
                                        <SubmitButton
                                            fullWidth
                                            disableElevation
                                            disableFocusRipple
                                            disableRipple
                                            type="submit"
                                            variant="contained"
                                            size="large"
                                            disabled={isSubmitting}
                                            style={
                                                isSubmitting
                                                    ? {
                                                          backgroundColor:
                                                              "#777",
                                                          color: "#FFF",
                                                          minWidth: "338px",
                                                      }
                                                    : {
                                                          backgroundColor:
                                                              "#393939",
                                                          color: "#FFF",
                                                          minWidth: "338px",
                                                      }
                                            }
                                            onClick={(event) => {
                                                handleSubmit();
                                            }}
                                        >
                                            <span className="cta">
                                                Save changes
                                            </span>
                                        </SubmitButton>
                                        <Button
                                            variant="text"
                                            onClick={() => {
                                                history.push("/texts/");
                                            }}
                                        >
                                            <span className="bold-link">
                                                Cancel
                                            </span>
                                        </Button>
                                    </ButtonGroup>
                                </Box>
                            </Box>
                        )}
                    </ColumnLeft>
                    <Column />
                </Container>
            </DataTemplate>
        </AdminTemplate>
    );
};

export default withStyles(styles)(TextDetails);
