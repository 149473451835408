import React from "react";
import styled from "styled-components";

const Container = styled.div`
    top: 0;
    bottom: 0;
    width: 100%;
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
`;

const InnerContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
`;

const Content = styled.div`
    display: flex;
    max-width: 1500px;
    flex: 1;
    flex-direction: column;
    margin-top: 120px;
`;

const Template = (props) => {
    return (
        <Container>
            <InnerContainer>
                <Content>{props.children}</Content>
            </InnerContainer>
        </Container>
    );
};

export default Template;
