import React, { useState } from "react";
import styled from "styled-components";
import { Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import DataTemplate from "../../components/DataTemplate.jsx";
import AdminTemplate from "../admin/template";
import ApproverForm from "./form";
import { useLocation, useHistory } from "react-router-dom";
import { protectedResources } from "../../authConfig";
import { capexApiGet, capexApiPost, capexApiDelete } from "../../fetch";
import { Container, ColumnLeft, Column } from "./../../components/Layout";
import Text from "../../components/Text.jsx";

const HGap = styled.div`
    width: 50px;
`;

const VGap = styled.div`
    height: 40px;
`;

const Content = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

const CenterBox = (props) => {
    return (
        <Box
            paddingTop="5px"
            paddingBottom="5px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            {...props}
        />
    );
};

const LeftBox = (props) => {
    return (
        <Box display="flex" paddingTop="5px" paddingBottom="5px" {...props} />
    );
};

const ApproverEdit = (props) => {
    const [data, setData] = useState();
    const [enableDelete, setEnableDelete] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const dataTemplateRef = React.useRef();
    const location = useLocation();
    const history = useHistory();

    const handleSubmit = async (values) => {
        const form = { ...values };
        setIsSubmitting(true);

        form.siteIDs = values.sites.map((aSite) => aSite.id);
        form.investmentTypeIDs = values.investmentTypes.map(
            (aType) => aType.id
        );

        const json = JSON.stringify(form);

        await capexApiPost(
            protectedResources.apiCapex.endpoint + "/approver/edit/",
            json
        );

        setIsSubmitting(false);
        dataTemplateRef.current.loadData();
    };

    const handleDelete = async (id) => {
        const result = await capexApiGet(
            protectedResources.apiCapex.endpoint + "/approver/delete/" + id
        );

        if (result.success) {
            setEnableDelete(true);
        }
    };

    const handleDeleteConfirm = async (id) => {
        await capexApiDelete(
            protectedResources.apiCapex.endpoint +
                "/approver/deleteConfirmed/" +
                id
        );

        history.push("/approver");
    };

    const activeRequests =
        data &&
        data.requests.filter((request) => {
            return request.status !== 1;
        });

    const approvedRequests =
        data &&
        data.requests.filter((request) => {
            return request.status === 1;
        });

    const initialValues =
        data && data.approver
            ? { ...data.approver }
            : {
                  graphUserId: "",
                  budgeted: 0,
                  investmentTypes: [],
                  level: 1,
                  maxAnually: 0,
                  name: "",
                  nonBudgeted: 0,
                  sites: [],
              };

    const totalBudgeted =
        data &&
        approvedRequests.reduce((previousValue, request) => {
            if (request.budgeted) {
                return previousValue + request.amount;
            } else {
                return previousValue;
            }
        }, 0);

    const totalNonBudgeted =
        data &&
        approvedRequests.reduce((previousValue, request) => {
            if (!request.budgeted) {
                return previousValue + request.amount;
            } else {
                return previousValue;
            }
        }, 0);

    const remainingNonBudgeted =
        data && data.approver.maxAnually - totalNonBudgeted;

    const date = new Date();

    return (
        <AdminTemplate {...props}>
            <DataTemplate
                {...props}
                url={protectedResources.apiCapex.endpoint + location.pathname}
                data={data}
                setData={setData}
                ref={dataTemplateRef}
            >
                <Container>
                    <ColumnLeft>
                        <Box gridColumn="span 12" marginBottom={"5px"}>
                            <span className="headline-15-caps">Admin</span>
                        </Box>
                        <Box marginBottom={"37px"}>
                            <span className="headline-l">
                                Edit/review Approver
                            </span>
                        </Box>
                        <Box marginBottom={"43px"}>
                            <span className="body-copy">
                                <Text id="approver_create_description" />
                            </span>
                        </Box>
                        <Box marginBottom={"18px"}>
                            <span className="headline-ml">
                                Current status {date.getFullYear()}
                            </span>
                        </Box>
                        <Box
                            display="grid"
                            gridTemplateColumns="repeat(12, 1fr)"
                            gridColumnGap={10}
                        >
                            <Box
                                gridColumn={"span 12"}
                                display="grid"
                                gridTemplateColumns="repeat(12, 1fr)"
                                gridColumnGap={10}
                                marginBottom={"16px"}
                            >
                                <Box gridColumn={"span 8"}>
                                    <span className="section">
                                        Active requests
                                    </span>
                                </Box>
                                <CenterBox gridColumn="span 2">
                                    <span className="label">Budgeted</span>
                                </CenterBox>
                                <CenterBox gridColumn="span 2">
                                    <span
                                        className="label"
                                        style={{
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                        }}
                                    >
                                        Non-budgeted
                                    </span>
                                </CenterBox>
                            </Box>
                            {data &&
                                activeRequests.map((request) => (
                                    <React.Fragment key={request.id}>
                                        <LeftBox gridColumn="span 4">
                                            <Link to={"/request/" + request.id}>
                                                <span className="label">
                                                    {request.name}
                                                </span>
                                            </Link>
                                        </LeftBox>
                                        <LeftBox gridColumn="span 4">
                                            <span className="label">
                                                {request.requester?.displayName}
                                            </span>
                                        </LeftBox>
                                        <CenterBox gridColumn="span 2">
                                            <span className="label">
                                                {request.budgeted ? (
                                                    <React.Fragment>
                                                        <span className="label">
                                                            {request.amount}
                                                        </span>
                                                        <span className="unit">
                                                            {" "}
                                                            kEUR
                                                        </span>
                                                    </React.Fragment>
                                                ) : (
                                                    "-"
                                                )}
                                            </span>
                                        </CenterBox>
                                        <CenterBox gridColumn="span 2">
                                            <span className="label">
                                                {!request.budgeted ? (
                                                    <React.Fragment>
                                                        <span className="label">
                                                            {request.amount}
                                                        </span>
                                                        <span className="unit">
                                                            {" "}
                                                            kEUR
                                                        </span>
                                                    </React.Fragment>
                                                ) : (
                                                    "-"
                                                )}
                                            </span>
                                        </CenterBox>
                                        <Box
                                            gridColumn="span 12"
                                            style={{
                                                opacity: "0.2",
                                                borderBottom:
                                                    "1px solid #707070",
                                            }}
                                        />
                                    </React.Fragment>
                                ))}

                            <Box gridColumn="span 12">
                                <VGap />
                            </Box>
                            {data && approvedRequests.length > 0 && (
                                <Box
                                    gridColumn="span 12"
                                    display="grid"
                                    gridTemplateColumns="repeat(12, 1fr)"
                                    gridColumnGap={10}
                                    marginBottom={"16px"}
                                >
                                    <Box gridColumn="span 8">
                                        <span className="section">
                                            Approved requests & Budget
                                        </span>
                                    </Box>
                                    <CenterBox gridColumn="span 2">
                                        <span className="label">Budgeted</span>
                                    </CenterBox>
                                    <CenterBox gridColumn="span 2">
                                        <span
                                            className="label"
                                            style={{
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            Non-budgeted
                                        </span>
                                    </CenterBox>
                                </Box>
                            )}
                            <Box
                                gridColumn="span 12"
                                marginBottom={"32px"}
                                display="grid"
                                gridTemplateColumns="repeat(12, 1fr)"
                                gridColumnGap={10}
                            >
                                {data &&
                                    approvedRequests.map((request) => (
                                        <React.Fragment key={request.id}>
                                            <LeftBox gridColumn="span 4">
                                                <Link
                                                    to={
                                                        "/request/" + request.id
                                                    }
                                                >
                                                    <span className="label">
                                                        {request.name}
                                                    </span>
                                                </Link>
                                            </LeftBox>
                                            <LeftBox gridColumn="span 4">
                                                <span className="label">
                                                    {
                                                        request.requester?.displayName
                                                    }
                                                </span>
                                            </LeftBox>
                                            <CenterBox gridColumn="span 2">
                                                <span className="label">
                                                    {request.budgeted ? (
                                                        <React.Fragment>
                                                            <span className="label">
                                                                {request.amount}
                                                            </span>
                                                            <span className="unit">
                                                                {" "}
                                                                kEUR
                                                            </span>
                                                        </React.Fragment>
                                                    ) : (
                                                        <span className="label">
                                                            -
                                                        </span>
                                                    )}
                                                </span>
                                            </CenterBox>
                                            <CenterBox gridColumn="span 2">
                                                <span className="label">
                                                    {!request.budgeted ? (
                                                        <React.Fragment>
                                                            <span className="label">
                                                                {request.amount}
                                                            </span>
                                                            <span className="unit">
                                                                {" "}
                                                                kEUR
                                                            </span>
                                                        </React.Fragment>
                                                    ) : (
                                                        <span className="label">
                                                            -
                                                        </span>
                                                    )}
                                                </span>
                                            </CenterBox>
                                            <Box
                                                gridColumn="span 12"
                                                style={{
                                                    opacity: "0.2",
                                                    borderBottom:
                                                        "1px solid #707070",
                                                }}
                                            />
                                        </React.Fragment>
                                    ))}
                            </Box>
                            <Box gridColumn="span 8">
                                <span className="input">Total</span>
                            </Box>
                            <CenterBox gridColumn="span 2">
                                {data && (
                                    <React.Fragment>
                                        <span className="input">
                                            {totalBudgeted}
                                        </span>
                                        <span className="unit">&nbsp;kEUR</span>
                                    </React.Fragment>
                                )}
                            </CenterBox>
                            <CenterBox gridColumn="span 2">
                                {data && (
                                    <React.Fragment>
                                        <span className="input">
                                            {totalNonBudgeted}
                                        </span>
                                        <span className="unit">&nbsp;kEUR</span>
                                    </React.Fragment>
                                )}
                            </CenterBox>
                            <Box gridColumn="span 8">
                                <span className="input">
                                    Remaining non-budget
                                </span>
                            </Box>
                            <CenterBox gridColumn="span 2">
                                <div>
                                    <span className="input">-</span>
                                </div>
                            </CenterBox>
                            <CenterBox gridColumn="span 2">
                                <div
                                    className={
                                        data && remainingNonBudgeted === 0
                                            ? "budget-warning"
                                            : ""
                                    }
                                >
                                    <span
                                        className={
                                            data && remainingNonBudgeted === 0
                                                ? "input color-white"
                                                : "input"
                                        }
                                    >
                                        {data && remainingNonBudgeted}
                                    </span>
                                    <span
                                        className={
                                            data && remainingNonBudgeted === 0
                                                ? "unit color-white"
                                                : "unit"
                                        }
                                    >
                                        {" "}
                                        kEUR
                                    </span>
                                </div>
                            </CenterBox>
                        </Box>
                    </ColumnLeft>
                    <HGap />
                    <Column>
                        <ApproverForm
                            data={data}
                            initialValues={initialValues}
                            isEditing
                            handleSubmit={handleSubmit}
                            handleDelete={handleDelete}
                            handleDeleteConfirm={handleDeleteConfirm}
                            enableDelete={enableDelete}
                            isSubmitting={isSubmitting}
                        ></ApproverForm>
                    </Column>
                </Container>
            </DataTemplate>
        </AdminTemplate>
    );
};

export default ApproverEdit;
