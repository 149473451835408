import React from "react";
import { AppBar, Toolbar } from "@material-ui/core";
import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from "./../img/hildinganderslogo.png";
import { useMsal } from "@azure/msal-react";

const Separator = styled.div`
    display: flex;
    flex: 1;
`;

const BorderLeftDiv = styled.div`
    opacity: 0.2;
    border-left: 1px solid #fff;
    height: 34px;
    margin-left: 20px;
    margin-right: 20px;
`;

const AppBarComponent = (props) => {
    const { instance } = useMsal();
    const currentAccount = instance.getActiveAccount();

    return (
        <AppBar
            position="sticky"
            style={{
                display: "flex",
                alignItems: "center",
                flex: 1,
            }}
        >
            <Toolbar style={{ display: "flex", flex: 1, width: "100%" }}>
                <Link to="/" className="nav-logo margin-right-25">
                    <img src={logo} alt={"logo"} />
                </Link>
                <BorderLeftDiv />
                {props.children}
                <Separator />
                {currentAccount && (
                    <div className="user-name">{currentAccount.name}</div>
                )}
                <BorderLeftDiv />
                <Link
                    to=""
                    className="logout-button"
                    onClick={() => {
                        instance.logoutRedirect({
                            postLogoutRedirectUri: "/",
                        });
                    }}
                >
                    Logout
                </Link>
            </Toolbar>
        </AppBar>
    );
};

export default AppBarComponent;
