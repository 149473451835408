import React, { useState } from "react";
import DataTemplate from "./../../components/DataTemplate.jsx";
import RequestForm from "./form";
import { useHistory } from "react-router-dom";
import RequestTemplate from "./template";
import { protectedResources } from "./../../authConfig";
import { capexApiPost, capexApiPut } from "../../fetch";
import { Container, ColumnLeft, Column } from "./../../components/Layout";
import { Box } from "@material-ui/core";
import Text from "../../components/Text.jsx";

const CreateRequest = (props) => {
    const [data, setData] = useState();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const history = useHistory();

    const handleSubmit = async (values) => {
        setIsSubmitted(true);

        values.amount = parseInt(values.amount);
        values.budgeted = values.budgeted === "true";

        const { file, ...otherValues } = values;

        if (file) {
            var body = new FormData();
            body.append("file", file);

            const fileUploadResponse = await capexApiPut(
                protectedResources.apiCapex.endpoint + "/file/",
                body
            );

            otherValues.fileName = fileUploadResponse.fileName;
            otherValues.fileDescription = fileUploadResponse.fileDescription;
        }

        const json = JSON.stringify(otherValues);
        const response = await capexApiPost(
            protectedResources.apiCapex.endpoint + "/request/create/",
            json
        );

        response && history.push("/request/confirm/" + response.request.id);
    };

    const initialValues = {
        name: "",
        amount: 0,
        fiscalYear: new Date().getFullYear(),
        startDate: new Date(),
        endDate: new Date(),
        budgeted: "true",
        siteId: 0,
        investmentTypeId: 0,
        divisionId: 0,
        financeTypeId: 0,
        investmentTypeId: 0,
        file: "",
    };

    if (data) {
        initialValues.siteId =
            data.availableSites &&
            data.availableSites.length > 0 &&
            data.availableSites[0].id;
        initialValues.investmentTypeId =
            data.availableInvestmentTypes &&
            data.availableInvestmentTypes.length > 0 &&
            data.availableInvestmentTypes[0].id;
        initialValues.divisionId =
            data.availableDivisions &&
            data.availableDivisions.length > 0 &&
            data.availableDivisions[0].id;
        initialValues.financeTypeId =
            data.availableFinanceTypes &&
            data.availableFinanceTypes.length > 0 &&
            data.availableFinanceTypes[0].id;
    }

    return (
        <RequestTemplate {...props}>
            <DataTemplate
                {...props}
                url={protectedResources.apiCapex.endpoint + "/request/create"}
                data={data}
                setData={setData}
            >
                <Container>
                    <ColumnLeft>
                        <Box paddingBottom={"29px"}>
                            <span className="headline-l">
                                Capex request form
                            </span>
                        </Box>
                        <Box>
                            <span className="body-copy">
                                <Text id="request_create_description" />
                            </span>
                        </Box>
                    </ColumnLeft>
                    <Column>
                        <RequestForm
                            data={data}
                            initialValues={initialValues}
                            handleSubmit={handleSubmit}
                            disabled={isSubmitted}
                        ></RequestForm>
                    </Column>
                </Container>
            </DataTemplate>
        </RequestTemplate>
    );
};

export default CreateRequest;
