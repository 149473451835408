import React, { useState } from "react";
import DataTemplate from "./../../components/DataTemplate.jsx";
import { Box, Button, ButtonGroup } from "@material-ui/core";
import { useLocation, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { capexApiGet, capexApiDelete } from "../../fetch";
import RequesterTemplate from "./template";
import ApproverTemplate from "./../approver/template";
import Status from "./../../components/Status";
import { protectedResources } from "./../../authConfig";
import History from "./history";
import CapexDetails from "./../../components/CapexDetails";
import DeleteImage from "./../../img/delete.png";
import { ContentGuard } from "./../../components/ContentGuard";
import {
    Container,
    ColumnLeft,
    Column,
    SectionWhiteBG,
} from "./../../components/Layout";

const RequestDetails = (props) => {
    const [data, setData] = useState();
    const [enableDelete, setEnableDelete] = React.useState(false);
    const [securityGroups, setSecurityGroups] = React.useState();
    const url = protectedResources.apiCapex.endpoint + "/groups/";
    const location = useLocation();
    const history = useHistory();

    const getGroups = async () => {
        const response = await capexApiGet(url);
        setSecurityGroups(response);
    };

    const { name, requester, status } = data ? data : {};
    const Template =
        props.template === "approver" ? ApproverTemplate : RequesterTemplate;

    console.log(securityGroups);

    const handleDelete = async (id) => {
        const result = await capexApiGet(
            protectedResources.apiCapex.endpoint + "/request/delete/" + id
        );

        if (result.success) {
            setEnableDelete(true);
        }
    };

    const handleDeleteConfirm = async (id) => {
        await capexApiDelete(
            protectedResources.apiCapex.endpoint +
                "/request/deleteConfirmed/" +
                id
        );

        history.push("/request");
    };

    return (
        <Template {...props}>
            <DataTemplate
                {...props}
                url={protectedResources.apiCapex.endpoint + location.pathname}
                data={data}
                setData={(data) => {
                    setData(data);
                    getGroups();
                }}
            >
                <Container>
                    <ColumnLeft>
                        <Box
                            display="grid"
                            gridTemplateColumns="repeat(12, 1fr)"
                            gridColumnGap={10}
                            paddingBottom={"41px"}
                        >
                            <Box gridColumn="span 12">
                                <span className="headline-15-caps">
                                    CAPEX REQUEST
                                </span>
                            </Box>
                            <Box gridColumn="span 12">
                                <span className="headline-l">{name}</span>
                            </Box>
                            <Box gridColumn="span 4">
                                <span className="label">Requested by:</span>
                            </Box>
                            <Box gridColumn="span 8">
                                <span className="label">Request status:</span>
                            </Box>
                            <Box gridColumn="span 4">
                                <span className="input">
                                    {requester && requester.displayName}
                                </span>
                            </Box>
                            <Box gridColumn="span 8">
                                <Status status={status} />
                            </Box>
                        </Box>
                        {!props.template && (
                            <Box marginBottom={"50px"}>
                                <Link to={"/request/"}>
                                    <span className="text-link">
                                        See all your requests
                                    </span>
                                </Link>
                            </Box>
                        )}
                        <SectionWhiteBG style={{ paddingTop: "41px" }}>
                            <History request={data} />
                        </SectionWhiteBG>
                        <div className="height-40" />
                    </ColumnLeft>
                    <Column>
                        <CapexDetails request={data} />
                        {securityGroups && (
                            <ContentGuard groups={[securityGroups.groupAdmin]}>
                                <Box
                                    display="grid"
                                    gridTemplateColumns="repeat(12, 1fr)"
                                    gridColumnGap={10}
                                    paddingBottom={"41px"}
                                >
                                    <Box
                                        marginTop={"25px"}
                                        marginBottom={"30px"}
                                        gridColumn="span 6"
                                    >
                                        <ButtonGroup
                                            color="primary"
                                            fullWidth
                                            disableElevation
                                            disableFocusRipple
                                            disableRipple
                                        >
                                            {enableDelete ? (
                                                <Button
                                                    variant="contained"
                                                    style={{
                                                        backgroundColor:
                                                            "#C72D2D",
                                                    }}
                                                    onClick={() => {
                                                        handleDeleteConfirm(
                                                            data.id
                                                        );
                                                    }}
                                                >
                                                    <span className="bold-link-confirm">
                                                        Confirm delete
                                                    </span>
                                                    <img src={DeleteImage} />
                                                </Button>
                                            ) : (
                                                <Button
                                                    variant="text"
                                                    onClick={() => {
                                                        handleDelete(data.id);
                                                    }}
                                                >
                                                    <span className="bold-link">
                                                        Delete request
                                                    </span>
                                                    <img src={DeleteImage} />
                                                </Button>
                                            )}
                                        </ButtonGroup>
                                    </Box>
                                </Box>
                            </ContentGuard>
                        )}
                    </Column>
                </Container>
            </DataTemplate>
        </Template>
    );
};

export default RequestDetails;
