import React from "react";

const RemainingBudget = ({ remainingBudget }) => {
    return (
        <div
            className={
                remainingBudget === 0
                    ? "budget-warning budget-cell"
                    : "budget-cell"
            }
        >
            <span
                className={
                    remainingBudget === 0 ? "input color-white" : "input"
                }
            >
                {remainingBudget}
            </span>
            <span
                className={remainingBudget === 0 ? "unit color-white" : "unit"}
            >
                &nbsp;kEUR
            </span>
        </div>
    );
};

export default RemainingBudget;
