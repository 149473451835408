import React from "react";
import { Link } from "@material-ui/core";
import { downloadFile } from "./../fetch";
import FileDownloadImage from "./../img/file-download.png";

const DownloadLink = ({ fileName }) => {
    const handleDownloadFile = async () => {
        await downloadFile(fileName);
    };

    return (
        <Link
            className="centerV cursor-pointer"
            onClick={() => {
                handleDownloadFile();
            }}
        >
            <span className="download-link padding-right-10">Download</span>
            <img src={FileDownloadImage} />
        </Link>
    );
};

export default DownloadLink;
