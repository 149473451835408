import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import styled from "styled-components";
import Template from "./Template.jsx";

const Container = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
`;

const LoadingComponent = () => {
    return (
        <Template>
            <Container>
                <CircularProgress />
            </Container>
        </Template>
    );
};

export default LoadingComponent;
