import React from "react";
import { Box } from "@material-ui/core";
import { formatDateWithTime } from "./../../utils/formatDate";
import Status from "./../../components/Status";

const HistoryEntry = ({ action, isLastIndex }) => {
    const { status, approvers, comment } = action;
    let actionDescription = comment;

    if (status === 0) {
        actionDescription = "Request sent for approval to:";
    }

    let statusElement = status != 0 && (
        <Box gridColumn="span 12">
            <Status status={status} />
        </Box>
    );

    return (
        <React.Fragment>
            <Box gridColumn="span 12">
                <span className="label">
                    {formatDateWithTime(action.created)}
                </span>
            </Box>
            {statusElement}
            <Box gridColumn="span 12">
                <span className="body-m ">{actionDescription}</span>
            </Box>
            <Box gridColumn="span 12">
                {approvers.map((approver) => (
                    <div key={approver.id}>
                        <span className="label">{approver.name}</span>
                    </div>
                ))}
            </Box>
            {!isLastIndex && <div className="height-20" />}
        </React.Fragment>
    );
};

const RequestHistory = (props) => {
    const { request } = props;
    const { actions } = request;

    return (
        <Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            className="request-history"
        >
            <Box gridColumn="span 12">
                <span className="section">REQUEST HISTORY</span>
            </Box>
            <div className="height-20" />
            {actions.map((action, index) => (
                <HistoryEntry
                    key={action.id}
                    action={action}
                    isLastIndex={index === actions.length - 1}
                />
            ))}
        </Box>
    );
};

export default RequestHistory;
